import { createSlice } from "@reduxjs/toolkit";
import appConfig, { ServiceType } from "../config/AppConfig";

type ProjectPlanningNodeType = ProjectPlanningLeafItem & ProjectPlanningNode & ProjectLayersPartitionItem;
interface ProjectPlanningState {
  data: ProjectPlanningNodeType;
}

interface ProjectPlanningNode {
  [key: string]: {
    expanded: boolean;
    node: ProjectPlanningNodeType;
  };
}

interface ProjectPlanningLeafItem {
  checked: boolean;
  extent: string;
}

interface ProjectLayersPartitionItem {
  sceneItemId: string;
  outlineItemId: string;
  terrainItemId: string;
  definitionExpression: Array<string>;
}
// initial state
const initialProjectPlanningState: ProjectPlanningState = { data: {} as ProjectPlanningNodeType };

// slice
const projectPlanningSlice = createSlice({
  name: "projectPlanning",
  initialState: initialProjectPlanningState,
  reducers: {
    selectLeafItem: (state, action) => {
      const { parentKeys, leafKey }: { parentKeys: string[]; leafKey: string } =
        action.payload;

      let parent = state.data as ProjectPlanningNode;
      for (const key of parentKeys) {
        if (!("checked" in parent[key])) {
          parent = parent[key].node as ProjectPlanningNode;
        }
      }
      parent[leafKey].node.checked = !parent[leafKey].node.checked;
    },

    // Recursively select all leaf items that are leaves of the current node
    selectAllLeafItems: (state, action) => {
      const { parentKeys, leafKey, newValue } = action.payload;
      const parent = parentKeys.reduce(
        (acc: ProjectPlanningNode, key: string) => acc[key].node,
        state.data
      );

      const selectAll = (node: ProjectPlanningNodeType) => {
        Object.keys(node).forEach((key) => {
          if (node.hasOwnProperty("checked")) {
            node["checked"] = newValue;
          } else {
            selectAll((node as ProjectPlanningNode)[key].node);
          }
        });
      };

      selectAll(parent[leafKey].node);
    },

    loadProjectLayers: (state, action: { payload: { attributesArray: Array<{ [key: string]: string | number }> }, type: string }) => {
      const newState: ProjectPlanningNodeType | any = {};
      const attributeHierarchy = appConfig.projectPlanning.attributeHierarchy;

      action.payload.attributesArray.forEach((tableAttributes) => {
        // Create a tree structure based on the attribute hierarchy
        let parent: ProjectPlanningNodeType = newState;
        attributeHierarchy.forEach((key, index) => {
          if (!(parent as ProjectPlanningNode)[tableAttributes[key]]) {
            const attributesLeaf = {
              checked: false,
              extent: tableAttributes["extent"],
            } as ProjectPlanningLeafItem;

            const attributesPartition = {
              outlineItemId: tableAttributes["outlines_item_id"],
              sceneItemId: tableAttributes["scene_item_id"],
              terrainItemId: tableAttributes["terrain_item_id"],
              definitionExpression: [], // default show all values
            } as ProjectLayersPartitionItem;

            let lNode = {
              expanded: false,
              node:
                index === attributeHierarchy.length - 1
                  ? attributesLeaf
                  : ({} as ProjectPlanningNodeType),
            };

            (parent as any)[tableAttributes[key]] =
              key === "partition"
                ? { ...lNode, ...attributesPartition }
                : lNode;
          }
          parent = (parent as ProjectPlanningNodeType)[tableAttributes[key]].node;
        });
      }
      );

      state.data = newState;
    },

    expandCollapseMenu: (state, action) => {
      const { parentKeys, title } = action.payload;

      let parent = state.data as ProjectPlanningNode;
      for (const key of parentKeys) {
        parent = parent[key].node as ProjectPlanningNode;
      }
      parent[title].expanded = !parent[title].expanded;
    },
  },
});

const layerShouldBeVisible = (
  state: ProjectPlanningState,
  id: string,
  dimension: string
) => {
  // Return true if any leaf node with the given id is true. Otherwise, return false.
  const recursiveSearch = (node: ProjectPlanningNode): boolean => {
    for (const key in node) {
      if (node[key]?.node === undefined) continue;
      if (
        recursiveSearch(node[key].node as ProjectPlanningNode) ||
        (node[key]?.node?.checked &&
          dimension === "2d" &&
          node[key]?.node?.outlineItemId === id) ||
        (dimension === "3d" &&
          (node[key]?.node?.sceneItemId === id ||
            node[key]?.node?.terrainItemId === id))
      ) {
        return true;
      }
    }
    return false;
  };
  return recursiveSearch(state.data);
};

const getAllLeafItemsWithSameItemId = (
  state: ProjectPlanningState,
  id: string
): string[] => {
  const recursiveSearch = (node: ProjectPlanningNode): string[] => {
    const leafItems: string[] = [];
    if (node === undefined) return leafItems;

    for (const key in node) {
      const nodeKey: ProjectPlanningNodeType | any = node[key];
      if (nodeKey?.node === undefined) continue;
      if (nodeKey?.node?.checked) {
        leafItems.push(key);
      }
      leafItems.push(...recursiveSearch(nodeKey.node as ProjectPlanningNode));
    }
    return leafItems;
  };
  return recursiveSearch(state.data);
};

const getAllLayerIds = (
  state: ProjectPlanningNode,
  dimension: string
): Set<string> => {
  const recursiveSearch = (node: ProjectPlanningNode): Set<string> => {
    let leafItems: Set<string> = new Set<string>();
    if (node === undefined) return leafItems;

    for (const key in node) {
      const nodeKey: ProjectPlanningNodeType | any = node[key];

      if (nodeKey?.node === undefined) continue;

      if (dimension === "2d" && nodeKey.outlineItemId) {
        leafItems.add(
          `${nodeKey.outlineItemId as string}|${ServiceType.FeatureLayer}`
        );
      }
      if (dimension === "3d") {
        if (nodeKey.sceneItemId)
          leafItems.add(
            `${nodeKey.sceneItemId as string}|${ServiceType.Scene}`
          );
        if (nodeKey.terrainItemId)
          leafItems.add(
            `${nodeKey.terrainItemId as string}|${ServiceType.ElevationLayer}`
          );
      }
      leafItems = new Set([
        ...leafItems,
        ...recursiveSearch(node[key].node as ProjectPlanningNode),
      ]);
    }
    return leafItems;
  };
  return recursiveSearch(state);
};

export default projectPlanningSlice.reducer;
export const {
  selectLeafItem,
  selectAllLeafItems,
  loadProjectLayers,
  expandCollapseMenu,
} = projectPlanningSlice.actions;
export type { ProjectPlanningState, ProjectPlanningNode, ProjectPlanningLeafItem, ProjectPlanningNodeType, ProjectLayersPartitionItem };
export { layerShouldBeVisible, getAllLeafItemsWithSameItemId, getAllLayerIds };
