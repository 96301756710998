import BasemapGallery from "@arcgis/core/widgets/BasemapGallery";
import ElevationProfile from "@arcgis/core/widgets/ElevationProfile";
import Expand from "@arcgis/core/widgets/Expand";
import Locate from "@arcgis/core/widgets/Locate";
import Search from "@arcgis/core/widgets/Search";

export const addBasemapGallery = (
  view: Array<__esri.MapView | __esri.SceneView>,
  position?: string
) => {
  view.forEach((view) => {
    // Create a BasemapGallery widget instance and set its container to a div element
    const basemapGallery = new BasemapGallery({
      view: view,
      container: document.createElement("div"),
    });

    // Create an Expand instance and set the content property to the DOM node of the basemap gallery widget
    const bgExpand = new Expand({
      view: view,
      content: basemapGallery,
    });
    view.ui.add(bgExpand, position ?? "top-right");
  });
};

export const addLocate = (
  view: Array<__esri.MapView | __esri.SceneView>,
  position?: string
) => {
  view.forEach((view) => {
    const locateWidget = new Locate({
      view: view,
      scale: 5000,
    });
    view.ui.add(locateWidget, position ?? "top-right");

    // remove graphic after 5 seconds
    // locateWidget.on("locate", (event: any) => {
    //   setTimeout(() => {
    //     view.graphics.remove(locateWidget.graphic);
    //   }, 5 * 1000);
    // });
  });
};

export const addGraphicToMap = (
  view: __esri.MapView | __esri.SceneView,
  graphic: __esri.Graphic,
  timeoutSec?: number) => {
  const graphicsLayer = view.map.findLayerById(
    "app_graphics"
  ) as __esri.GraphicsLayer;

  if (graphicsLayer) {
    graphicsLayer.removeAll();
    graphicsLayer.add(graphic);
    if (timeoutSec) {
      setTimeout(() => {
        graphicsLayer.removeAll();
      }, timeoutSec * 1000);
    }
  };
};

export const addSearchWidget = (
  view: __esri.MapView | __esri.SceneView,
  container: string | HTMLElement
) => {
  // console.debug("Adding search widget to view", view.ui.components.forEach(component => console.log(component)))
  new Search({
    view: view,
    container: container,
    popupEnabled: false,
    resultGraphicEnabled: false,
    includeDefaultSources: false,
    sources: [
      // use default source as custom sources to set properties
      {
        name: "LocatorSearchSource",
        url: "https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer",
        zoomScale: 20_000,
        countryCode: "NO",
      } as __esri.LocatorSearchSourceProperties,
    ],
  });
};

export const addCrossSectionWidget = (
  view: Array<__esri.MapView | __esri.SceneView>,
  position?: string
) => {
  const sceneViews = view.filter((v) => v.type === "3d");
  const profiles2d = sceneViews.map((v) => ({
    type: "query",
    source: v.map.ground,
  }));
  const profiles3d = [{ type: "ground" }, { type: "view" }];

  view.forEach((view) => {
    const crossSectionWidget = new ElevationProfile({
      view: view,
      profiles: (view.type === "2d"
        ? profiles2d
        : profiles3d) as unknown as __esri.Collection<
          | __esri.ElevationProfileLineGround
          | __esri.ElevationProfileLineInput
          | __esri.ElevationProfileLineQuery
          | __esri.ElevationProfileLineView
        >,
      unit: "metric",
      visibleElements: { selectButton: false },
    });

    const button = document.createElement("calcite-button");
    button.setAttribute("class", "esri-widget--button");
    button.setAttribute("appearance", "solid");
    button.setAttribute("kind", "neutral");
    button.setAttribute("scale", "m");
    button.setAttribute("type", "button");
    button.setAttribute("width", "auto");
    button.setAttribute("title", "Tverrsnitt");
    button.setAttribute("alignment", "center");
    button.setAttribute("icon-start", "altitude");

    button.onclick = () => {
      if (view.ui.find(crossSectionWidget.id)) {
        view.ui.remove(crossSectionWidget);
        button.iconStart = "altitude";
      } else {
        view.ui.add(crossSectionWidget, position ?? "top-right");
        button.iconStart = "chevrons-right";
      }
    };

    view.ui.add(button, position ?? "top-right");
  });
};

export const moveWidget = (
  view: Array<__esri.MapView | __esri.SceneView>,
  widgetName: string,
  position?: string,
  layout?: "vertical" | "horizontal"
) => {
  view.forEach((view) => {
    view.ui.move(widgetName, position ?? "bottom-right");
    if (layout) {
      const w = view.ui.find(widgetName) as __esri.Zoom;
      w.layout = layout;
    }
  });
};
