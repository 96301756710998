import { SpatialReference, Extent } from "@arcgis/core/geometry";
import {
  addSublayersFullExtent,
  setVisibleSubLayers,
} from "../map/MapServiceFactoryUtils";
import Color from "@arcgis/core/Color";

export enum Dimension {
  Only2D = "2D",
  Only3D = "3D",
  Both = "Both",
}
export enum ServiceType {
  MapImageLayer = "map-image",
  WMSLayer = "wms",
  FeatureLayer = "feature",
  VectorTileLayer = "vector-tile",
  TileLayer = "tile",
  PortalItem = "portal-item",
  GraphicsLayer = "graphic",
  ImageryLayer = "imagery",
  Scene = "scene",
  WMSTLayer = "wmts",
  ElevationLayer = "elevation",
}

export interface ConfigMapLayer {
  id?: string;
  title?: string;
  dimension?: Dimension;
  type: ServiceType;
  onLoadCallback?: Function;
  properties:
  | __esri.MapImageLayerProperties
  | __esri.WMSLayerProperties
  | __esri.FeatureLayerProperties
  | __esri.VectorTileLayerProperties
  | __esri.TileLayerProperties
  | __esri.PortalItemProperties
  | __esri.GraphicsLayerProperties
  | __esri.ImageryLayerProperties
  | __esri.SceneLayerProperties
  | __esri.WMTSLayerProperties
  | __esri.ElevationLayerProperties;
}

interface AppConfig {
  app: {
    clientSecret: string;
    portalUrl: string;
    apiUrl: string;
  };
  ui: {
    maxMobileWidth: number;
  };
  map2D: {
    spatialReference: __esri.SpatialReference;
    default: {
      basemap: ConfigMapLayer[];
      extent: __esri.Extent;
      zoom: number;
    };
  };
  map3D: {
    spatialReference: __esri.SpatialReference;
    default: {
      basemap: ConfigMapLayer[];
      ground: {
        layers: ConfigMapLayer[];
        navigationConstraint: __esri.GroundNavigationConstraintProperties;
        surfaceColor?: __esri.Color;
        opacity?: number;
      };
      extent?: __esri.Extent;
      zoom?: number;
    };
  };
  layers: Array<ConfigMapLayer>;
  projectPlanning: {
    metadataTableUrl: string;
    attributeHierarchy: string[];
  };
}

const spatialReference = new SpatialReference({ wkid: 25833 });
const initExtent = new Extent({
  xmin: 570493,
  ymin: 7590411,
  xmax: 522800,
  ymax: 7617162,
  spatialReference: spatialReference,
});

const appConfig: AppConfig = {
  app: {
    clientSecret: "M0yaQbaf5pMabH50",
    portalUrl: "https://agek.haehre.cloudgis.no/enterprise",
    apiUrl: window.HAERE_GIS_APP.app_apiUrl,
  },
  ui: {
    maxMobileWidth: 500,
  },
  map2D: {
    spatialReference: spatialReference,
    default: {
      basemap: [
        {
          type: ServiceType.VectorTileLayer,
          properties: {
            url: "https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheGraatone/VectorTileServer",
          } as __esri.VectorTileLayerProperties,
        },
      ],
      extent: initExtent,
      zoom: 8,
    },
  },
  map3D: {
    spatialReference: spatialReference,
    default: {
      basemap: [
        {
          type: ServiceType.VectorTileLayer,
          properties: {
            url: "https://services.geodataonline.no/arcgis/rest/services/GeocacheVector/GeocacheGraatoneTerreng/VectorTileServer",
          } as __esri.VectorTileLayerProperties,
        },
      ],
      ground: {
        layers: [
          {
            type: ServiceType.ElevationLayer,
            properties: {
              url: "https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_EUREF89/GeocacheTerreng/ImageServer",
            } as __esri.ElevationLayerProperties,
          },
        ],
        navigationConstraint: { type: "none" },
        surfaceColor: new Color([0, 0, 0]),
        opacity: 1,
      },
      extent: initExtent,
      zoom: 8,
    },
  },
  layers: [
    // add to map by order
    {
      type: ServiceType.WMSLayer,
      dimension: Dimension.Both,
      title: "Drone WMS", // from service
      onLoadCallback: (layer: __esri.WMSLayer) => {
        setVisibleSubLayers(layer);
        addSublayersFullExtent(layer);
      },
      properties: {
        portalItem: {
          id: "6cea6cc772ec450c9de6b54cfda469ba",
        },
        visible: false,
        customVisibleSublayers: [],
      } as __esri.WMSLayerProperties,
    },
    {
      id: "app_graphics",
      type: ServiceType.GraphicsLayer,
      dimension: Dimension.Both,
      title: "App graphics",
      properties: {
        id: "app_graphics",
        title: "App graphics",
        visible: true,
      } as __esri.GraphicsLayerProperties,
    },
  ],
  projectPlanning: {
    metadataTableUrl: window.HAERE_GIS_APP.projectPlanning_metadataTableUrl,
    attributeHierarchy: [
      "wbs",
      "category",
      "partition",
      "type",
      "theme",
      "name",
    ],
  },
};

export default appConfig;
