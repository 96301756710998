import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Card, Checkbox, Collapse, styled, Typography } from "@mui/material";
import {
  expandCollapseMenu,
  ProjectPlanningNodeType,
  selectAllLeafItems,
} from "../../../../store/projectPlanningSlice";
import NestedLayerItem from "./NestedLayerItem";
import { useDispatch } from "react-redux";
import useProjectPlanningTreeCheckStatus from "../../../../hooks/useProjectPlanningTreeCheckStatus";

const LayerItemCollapsibleMenu = (props: {
  projectPlanningMetadata: ProjectPlanningNodeType;
  metadataKeyAndtitle: string;
  parentKeys: string[];
  onZoomToLayer?: (extentString: string) => void;
}) => {
  const { projectPlanningMetadata, metadataKeyAndtitle, parentKeys, onZoomToLayer } = props;
  const hasCheckbox = parentKeys.length >= 2;
  const nodeData = projectPlanningMetadata[metadataKeyAndtitle];
  const { allChecked, anyChecked } = useProjectPlanningTreeCheckStatus([...parentKeys, metadataKeyAndtitle]);
  const dispatch = useDispatch();

  const CollapsibleItemMenuDiv = styled("div")(({ theme }) => ({
    padding: "5px 20px 5px 10px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    userSelect: "none",
    marginLeft: `${(parentKeys?.length ?? 0) * 15}px`,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  }));

  return (
    <>
      <CollapsibleItemMenuDiv
        style={{
          padding: "5px 20px 5px 10px",
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          userSelect: "none",
          marginLeft: `${(parentKeys?.length ?? 0) * 15}px`,
        }}
      >
        {hasCheckbox && <Checkbox
          name={metadataKeyAndtitle + Math.floor(Math.random() * 10000)}
          checked={allChecked}
          indeterminate={anyChecked && !allChecked}
          onChange={() => {
            dispatch(
              selectAllLeafItems({
                parentKeys,
                leafKey: metadataKeyAndtitle,
                newValue: !allChecked,
              })
            )
          }
          }
        />
        }

        <Card
          sx={{
            backgroundColor: "transparent",
          }}
          elevation={0}
          onClick={() => {
            dispatch(expandCollapseMenu({ parentKeys, title: metadataKeyAndtitle }));
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Typography
            sx={{
              color: "text.primary",
              fontWeight: "bold",
              fontSize: parentKeys?.length === 0 ? "20px" : "16px",
            }}
          >
            {metadataKeyAndtitle}
          </Typography>
          {nodeData.expanded ? <ExpandLess /> : <ExpandMore />}
        </Card>
      </CollapsibleItemMenuDiv>
      <Collapse in={nodeData.expanded}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NestedLayerItem
            projectPlanningMetadata={nodeData.node as ProjectPlanningNodeType}
            parentKeys={[...(parentKeys ?? []), metadataKeyAndtitle]}
            onZoomToLayer={onZoomToLayer}
          />
        </div>
      </Collapse>
    </>
  );
};

export default LayerItemCollapsibleMenu;
