import { createSlice } from "@reduxjs/toolkit";

// Profildata hentes fra Ditio!
const titles = {
  MACHINEOPERATOR: "user.groups.machineOperator",
  TRUCKDRIVER: "user.groups.truck",
};

const employeeTypes = {
  EMPLOYEE: "user.employeeTypes.employee",
  SUBCONTRACTOR: "user.employeeTypes.subcontractor",
};

const accessLevels = {
  ADMIN: "user.accessLevels.admin",
  USER: "user.accessLevels.user",
};

interface ProfileInfo {
  id: number;
  name: string;
  title: string;
  imgPath: string;
  phone: string;
  email: string;
  employeeType: string;
  accessLevel: string;
  groups: number[];
  company: string;
}

interface ProfileState {
  currentProfileId: number;
  profiles: ProfileInfo[];
  accessLevel: string;
}

const profileInfo: ProfileInfo[] = [
  // {
  //   id: 0,
  //   name: "Magnus Jensen",
  //   title: titles.MACHINEOPERATOR,
  //   imgPath: "",
  //   phone: "+4791827364",
  //   email: "magnus.jensen@haehre.no",
  //   employeeType: employeeTypes.EMPLOYEE,
  //   accessLevel: accessLevels.USER,
  //   groups: [9, 1, 2, 8],
  //   company: "HÆHRE",
  // },
  // {
  //   id: 1,
  //   name: "Siri Østhuus Jacobsen",
  //   title: titles.TRUCKDRIVER,
  //   imgPath: "",
  //   phone: "+4791827364",
  //   email: "siri.jacobsen@kstransport.no",
  //   employeeType: employeeTypes.SUBCONTRACTOR,
  //   accessLevel: accessLevels.USER,
  //   groups: [9, 6],
  //   company: "K. SIMONSEN TRANSPORT AS",
  // },
];

const initialProfileState = {
  /* name: "Magnus Jensen",
  title: titles.OPERATOR,
  imgPath: "",
  phone: "+4791827364",
  email: "magnus.jensen@haehre.no",
  employeeType: employeeTypes.EMPLOYEE,
  accessLevel: accessLevels.USER,
  groups: [9, 1, 2, 8],
  company: "HÆHRE", */
  accessLevel: accessLevels.USER,
  currentProfileId: 0,
  profiles: profileInfo,
};

const getProfile = (profiles: ProfileInfo[], id: number) => {
  return profiles.find((profile) => profile.id === id);
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialProfileState,
  reducers: {
    setCurrentProfile(state, action) {
      const { accessLevel, currentProfileId } = action.payload;
      state.currentProfileId = currentProfileId;
      state.accessLevel = accessLevel;
    },
    addProfile(state, action) {
      state.profiles = [...state.profiles, action.payload];
    },
  },
});

export default profileSlice.reducer;
export const { setCurrentProfile, addProfile } = profileSlice.actions;
export type { ProfileState, ProfileInfo };
export { titles, employeeTypes, accessLevels, getProfile };
