import { IconButton } from "@mui/material"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { useDispatch, useSelector } from "react-redux"
import { closeMenuItems, toggleExpanded } from "../../store/menuSlice"
import { StoreState } from "../../store/rootReducer"

const ExpandCollapse = () => {
  const expanded = useSelector((state: StoreState) => state.menu.expanded)
  const dispatch = useDispatch()

  return (
    <div className="expand-collapse">
      <IconButton
        onClick={() => {
          dispatch(closeMenuItems())
          dispatch(toggleExpanded())
        }}
        sx={{
          border: 2,
          borderColor: "divider",
          borderRadius: "15px",
          padding: "3px",
        }}
      >
        {expanded ? (
          <ChevronLeftIcon
            className="expand-collapse-icon"
            fontSize="large"
            sx={{ color: "primary.main" }}
          />
        ) : (
          <ChevronRightIcon
            className="expand-collapse-icon"
            fontSize="large"
            sx={{ color: "primary.main" }}
          />
        )}
      </IconButton>
    </div>
  )
}

export default ExpandCollapse
