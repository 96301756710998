import SubmenuTitle from "../SubmenuTitle"
import "../../../../styles/Profile.css"
import ProfileAvatar from "./ProfileAvatar"
import ProfileContact from "./ProfileContact"
import ProfileDetails from "./ProfileDetails" 
import { Button } from "@mui/material"
import { I18n } from "react-redux-i18n"
import esriId from "@arcgis/core/identity/IdentityManager.js"
import Submenu from "../Submenu"
import { useSelector } from "react-redux"
import { StoreState } from "../../../../store/rootReducer"

const SubmenuProfile = () => {
  const currentProfileId = useSelector((state: StoreState) => state.profile.currentProfileId)

  return (
    <Submenu>
      <SubmenuTitle text={I18n.t("sidebar.profile.title")} />
      <div className="sidebar-submenu-content">
        <div className="profile">
          <ProfileAvatar profileId={currentProfileId} />
          <ProfileContact profileId={currentProfileId}  />
          <ProfileDetails profileId={currentProfileId} />
        </div>
        <Button
          className="profile-button-logout"
          variant="contained"
          onClick={() => {
            esriId.destroyCredentials()
            window.location.reload()
          }}
        >
          {/* <Translate value="sidebar.profile.login" /> */}
          Logg ut
        </Button>
      </div>
    </Submenu>
  )
}

export default SubmenuProfile
