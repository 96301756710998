import { Typography } from "@mui/material";
import ProfileGroups from "./ProfileGroups";
import ProfileEmployeeType from "./ProfileEmployeeType";

const ProfileDetails = (props: { profileId: number }) => {
  const { profileId } = props;

  return (
    <div className="profile-details">
      <span className="profile-details-title">
        <ProfileEmployeeType profileId={profileId} />
      </span>
      <table>
        <tbody>
          <tr>
            <td>
              <Typography sx={{ color: "text.secondary" }}>Firma</Typography>
            </td>
            <td>
              <Typography sx={{ color: "text.primary" }}>HÆHRE</Typography>
            </td>
          </tr>
          <ProfileGroups profileId={profileId} />
        </tbody>
      </table>
    </div>
  );
};

export default ProfileDetails;
