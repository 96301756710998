import { useCallback, useEffect, useMemo, useState } from 'react';
import { ProjectPlanningNodeType } from '../store/projectPlanningSlice';
import { useSelector } from 'react-redux';
import { StoreState } from '../store/rootReducer';

/// This hook is used to check the status of the checkboxes in the project planning tree
/// It takes a path as an argument, which is the path to the node in the tree
/// It returns an object with the following properties:
/// allChecked: boolean - true if all children are checked
/// anyChecked: boolean - true if any children are checked
/// checkedCount: number - number of checked checkboxes
/// checkedHash: string - a string representation of the checked checkboxes
const useProjectPlanningTreeCheckStatus = (path: string[] = [], debounceTime = 15) => {
    const fullData = useSelector((state: StoreState) => state.projectPlanning.data);
    const [debouncedData, setDebouncedData] = useState(fullData);

    const getNodeAtPath = useCallback((data: ProjectPlanningNodeType, nodePath: string[]): ProjectPlanningNodeType | { checked: boolean } => {
        return nodePath.reduce((acc, key) => acc[key].node, data as any);
    }, []);

    const getCheckedStatus = useCallback((
        nodeData: ProjectPlanningNodeType | { checked: boolean },
        checkFunction: (data: ProjectPlanningNodeType) => boolean
    ): boolean => {
        if (nodeData === undefined) {
            return false;
        }
        return 'checked' in nodeData
            ? nodeData.checked
            : checkFunction(nodeData as ProjectPlanningNodeType);
    }, []);

    const areAllChildrenChecked = useCallback((nodeData: ProjectPlanningNodeType): boolean => {
        if (nodeData === undefined) {
            return false;
        }
        return Object.keys(nodeData).every((key) =>
            getCheckedStatus(nodeData[key].node, areAllChildrenChecked)
        );
    }, [getCheckedStatus]);

    const areAnyChildrenChecked = useCallback((nodeData: ProjectPlanningNodeType): boolean => {
        if (nodeData === undefined) {
            return false;
        }
        return Object.keys(nodeData).some((key) =>
            getCheckedStatus(nodeData[key].node, areAnyChildrenChecked)
        );
    }, [getCheckedStatus]);

    const countCheckedBoxes = useCallback((nodeData: ProjectPlanningNodeType | { checked: boolean }): number => {
        if ('checked' in nodeData) {
            return nodeData.checked ? 1 : 0;
        }
        let count = 0;
        for (const key in nodeData as any) {
            count += countCheckedBoxes((nodeData as any)[key].node);
        }
        return count;
    }, []);

    const generateCheckedHash = useCallback((nodeData: ProjectPlanningNodeType | { checked: boolean }): string => {
        if ('checked' in nodeData) {
            return nodeData.checked ? '1' : '0';
        }
        const checkedPaths: string[] = [];
        const traverse = (node: ProjectPlanningNodeType, nodePath: string[] = []) => {
            for (const key in node) {
                const currentNode = node[key].node;
                const currentPath = [...nodePath, key];
                if ('checked' in currentNode) {
                    if (currentNode.checked) {
                        checkedPaths.push(currentPath.join('.'));
                    }
                } else {
                    traverse(currentNode as ProjectPlanningNodeType, currentPath);
                }
            }
        };
        traverse(nodeData);
        return checkedPaths.sort().join('|');
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDebouncedData(fullData);
        }, debounceTime);

        return () => clearTimeout(timer);
    }, [fullData, debounceTime]);

    const memoizedResults = useMemo(() => {
        const debouncedNode = getNodeAtPath(debouncedData, path);
        const checkedCount = countCheckedBoxes(debouncedNode);
        const checkedHash = generateCheckedHash(debouncedNode);
        return {
            allChecked: 'checked' in debouncedNode ? debouncedNode.checked : areAllChildrenChecked(debouncedNode as ProjectPlanningNodeType),
            anyChecked: 'checked' in debouncedNode ? debouncedNode.checked : areAnyChildrenChecked(debouncedNode as ProjectPlanningNodeType),
            checkedCount,
            checkedHash,
        };
    }, [debouncedData, path, getNodeAtPath, areAllChildrenChecked, areAnyChildrenChecked, countCheckedBoxes, generateCheckedHash]);

    return memoizedResults;
};

export default useProjectPlanningTreeCheckStatus;