import ChevronRight from "@mui/icons-material/ChevronRight";
import { Typography, styled, useMediaQuery } from "@mui/material";
import LayerVisibleIndicator from "./LayerVisibleIndicator";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/rootReducer";
import {
  setActiveMenuItem,
  setExpanded,
  setVisibleMobile,
} from "../../store/menuSlice";
import theme from "../../styles/theme";

const SideBarItem = (props: {
  id: number;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  text: string;
  shownInMap?: boolean;
  children?: React.ReactNode;
  desktopOnly?: boolean;
  mobileOnly?: boolean;
  isOnBottomBar?: boolean;
}) => {
  const {
    id,
    Icon,
    text,
    shownInMap,
    children,
    desktopOnly,
    mobileOnly,
    isOnBottomBar,
  } = props;

  const activeMenuItem = useSelector(
    (state: StoreState) => state.menu.activeMenuItem
  );
  const expanded = useSelector((state: StoreState) => state.menu.expanded);
  const dispatch = useDispatch();

  const isActive = () => {
    return activeMenuItem === id;
  };

  const darkmode = useSelector((state: StoreState) => state.app.darkmode);
  const isMobile = useMediaQuery("(max-width: 500px)");

  const showIfActive = (color: string) => {
    return isActive() ? color : "none";
  };
  const fillIconIfActive = (colorActive: string, colorInactive: string) => {
    return isActive() ? colorActive : colorInactive;
  };

  const SidebarItemDiv = styled("div")(({ theme }) => ({
    backgroundColor: isOnBottomBar
      ? showIfActive(theme.palette.primary.light)
      : showIfActive(theme.palette.secondary.light),
    borderColor: isActive() ? theme.palette.primary.main : "none",
  }));

  const iconFill = isOnBottomBar
    ? fillIconIfActive(
        theme(darkmode).palette.secondary.main,
        theme(true).palette.primary.main
      )
    : theme(darkmode).palette.primary.main;

  return (
    <>
      <SidebarItemDiv
        className={`sidebar-item${isActive() ? " sidebar-item-active" : ""}${
          desktopOnly ? " sidebar-item-desktop-only" : ""
        }${mobileOnly ? " sidebar-item-mobile-only" : ""}`}
        onClick={() => {
          if (
            (isMobile && !isActive()) ||
            (isMobile && isOnBottomBar) ||
            !isMobile
          ) {
            dispatch(setActiveMenuItem(id));
            dispatch(setExpanded(false));
          }
          if (isOnBottomBar) {
            dispatch(setVisibleMobile(false));
          }
        }}
      >
        <div
          className={`submenu-icon${
            isOnBottomBar ? " submenu-icon-bottom" : ""
          }`}
        >
          <Icon fill={iconFill} />
        </div>
        {shownInMap ? <LayerVisibleIndicator expanded={expanded} /> : <></>}
        {expanded ? (
          <>
            <div>
              <Typography variant="h2" sx={{ color: "text.primary" }}>
                {text}
              </Typography>
            </div>
            {React.Children.count(children) > 0 ? (
              <div className="arrow-expand-menuitem">
                <ChevronRight fontSize="small" />
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </SidebarItemDiv>
      {isActive() ? children : <></>}
    </>
  );
};

export default SideBarItem;
