import { useContext, useEffect, useState } from "react";
import { ServiceType } from "../config/AppConfig";
import ArcGISViewContext from "../context/ArcGISViewContext";
import MapServiceFactory from "../map/MapServiceFactory";
import { addLayerToMap } from "../map/MapUtils";


export interface useHookLayer {
  layerprops: __esri.LayerProperties;
  type: ServiceType;
}

// keep cache of loaded layers.
// Possible refactoring is to add all alyers to map as visible false and then toggle visibility
let useCreateLoadAGSLayersCache: { [key: string]: __esri.Layer } = {};

/**
 * useCreateLoadAGSLayers Hook
 * @usage : useCreateLoadAGSLayers(portalLayerIds, type, doLoadOnInit, doAddLayerToMap);
 * @description : Custom hook used for loading a list of map service layers, based on portalItemsIds and type of mapservice.
 * @parameters : useHookLayer[] is a list of portalItems Ids and its mapservice type.
 * @parameters : dimension is 2d or 3d view.
 * @parameters : doLoadOnInit is if layer should load before return.
 * @parameters : doAddLayerToMap is if layer should be added to map (loaded).
 */
export function useCreateLoadAGSLayers(
  useHookLayers: useHookLayer[],
  dimension: "2d" | "3d",
  doLoadOnInit: boolean = true,
  doAddLayerToMap: boolean = true,
) {
  const { activeView } = useContext(ArcGISViewContext);
  const [loadedLayers, setLoadedLayers] = useState<__esri.Layer[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  let loadedLayerViews: Array<any> = [];

  // internal function to load layers
  const loadLayers = async () => {
    console.debug("useCreateLoadAGSLayers::Loading layers", useHookLayers);

    // internal function to load layer
    const createAndLoadLayers = useHookLayers.map(async (useHookLayer) => {
      setLoading(true);
      const { layerprops, type } = useHookLayer;
      const portalId = (layerprops as any).portalItem.id;

      // is in map
      const layerSearch = activeView.map.findLayerById(portalId);
      if (layerSearch != null) {
        setLoading(false);
        return layerSearch;
      }

      // is in cache
      if (useCreateLoadAGSLayersCache[portalId]) {
        setLoading(false);
        return useCreateLoadAGSLayersCache[portalId];
      }

      console.debug("useCreateLoadAGSLayers::Create layers", loadedLayers);
      const layer = MapServiceFactory.create({
        type: type,
        properties: layerprops,
      });

      // if not loading on init, just return layer
      if (!doLoadOnInit) {
        setLoading(false);
        return layer;
      }

      // load layer and maybe add to map
      if (doLoadOnInit || doAddLayerToMap) {
        try {
          // load layer
          (await layer.load()) as Promise<__esri.Layer>;
          useCreateLoadAGSLayersCache[portalId] = layer;

          if (doAddLayerToMap) {
            addLayerToMap(layer, activeView);
          }

          // wait and check loaded events for layerview, before set loading or error
          await activeView.whenLayerView(layer);
          loadedLayerViews.push(layer.id);
          if (useHookLayers.length === loadedLayerViews.length) {
            setLoading(false);
          }
        } catch (error: any) {
          loadedLayerViews.push(layer.id);
          if (useHookLayers.length === loadedLayerViews.length) {
            setLoading(false);
          }
          // An error occurred during the layerview creation
          setError("Failed to create layer. Error: " + error?.message);
        };
      }

      return layer;
    });

    try {
      // instead of uing Promise.all, that fails on first error, use Promise.allSettled and check result of each Promise
      let loadedLayers: __esri.Layer[] = [];
      const promises = await Promise.allSettled(createAndLoadLayers);
      promises.forEach((promise) => {
        if (promise.status === "fulfilled") {
          loadedLayers.push(promise.value);
        }
        if (promise.status === "rejected") {
          setError("Failed to load layer. Error: " + promise.reason);
        }
      });

      const sortedLayers = loadedLayers.toSorted((a: any, b: any) =>
        a.title.localeCompare(b.title)
      );

      setLoadedLayers(sortedLayers);
    } catch (error) {
      console.error("useCreateLoadAGSLayers::Failed to load layers", error);
      setError("Failed to load layers. Error: " + error);
    }
  };

  // trigger useeffect when layerIds are added
  useEffect(() => {
    if (
      activeView &&
      activeView.type === dimension &&
      loadedLayers.length === 0 &&
      useHookLayers.length > 0
    ) {
      loadLayers();
    }
  }, [useHookLayers]);

  return {
    loadedLayers,
    loading,
    error,
  };
}