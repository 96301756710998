import { createSlice } from "@reduxjs/toolkit"
import { dateCompare } from "../utils"

const colorIds: { [index: number]: string } = {
  0: "#0361A3",
  1: "#F5373F",
  2: "#9800E0",
  3: "#000000",
  4: "#00B127",
  5: "#F1F509",
  6: "#09E7F5",
  7: "#EE8100",
}

interface NoteGraphicObject {
  objectId: number
  name: string
  description: string
  noteId: number
}

interface NoteObject {
  id: number
  name: string
  date: string
}

interface NotesGlobalState {
  isDrawing: boolean
  colorIdGlobal: number
  noteGraphics: NoteGraphicObject[]
  tempNoteGraphics: NoteGraphicObject[]
  tempNoteName: string
  graphicNameGlobal: string
  graphicDescriptionGlobal: string
  showNameInMapGlobal: boolean
  noteNameGlobal: string
  graphicsSelected: number[]
  notes: NoteObject[]
  currentNoteId: number
  noteIdCounter: number
  currentNoteIsNew: boolean
}

const initialAppState: NotesGlobalState = {
  isDrawing: false,
  colorIdGlobal: 0,
  noteGraphics: [],
  tempNoteGraphics: [],
  tempNoteName: "",
  graphicNameGlobal: "",
  graphicDescriptionGlobal: "",
  showNameInMapGlobal: false,
  noteNameGlobal: "",
  graphicsSelected: [],
  notes: [],
  currentNoteId: 0,
  noteIdCounter: 0,
  currentNoteIsNew: false,
}

const notesSlice = createSlice({
  name: "notes",
  initialState: initialAppState,
  reducers: {
    addNoteObject(state, action) {
      state.currentNoteId = state.noteIdCounter
      state.notes = [
        ...state.notes,
        {
          id: state.noteIdCounter++,
          name: action.payload,
          date: new Date().toISOString(),
        },
      ]
    },
    removeNoteObject(state, action) {
      state.notes = state.notes.filter((note) => note.id !== action.payload)
      state.noteGraphics = state.noteGraphics.filter(
        (note) => note.noteId !== action.payload,
      )
    },
    setisDrawing(state, action) {
      state.isDrawing = action.payload
    },
    setColorIdGlobal(state, action) {
      state.colorIdGlobal = action.payload
    },
    addNoteGraphic(state, action) {
      state.noteGraphics = [
        ...state.noteGraphics,
        {
          objectId: action.payload,
          name: state.graphicNameGlobal,
          description: state.graphicDescriptionGlobal,
          noteId: state.currentNoteId,
        },
      ]
    },
    removeNoteGraphic(state, action) {
      state.noteGraphics = state.noteGraphics.filter(
        (graphic) => !action.payload.includes(graphic.objectId),
      )
    },
    setTempGraphics(state) {
      state.tempNoteGraphics = [...state.noteGraphics]
    },
    revertGraphics(state) {
      state.noteGraphics = [...state.tempNoteGraphics]
    },
    setTempNoteName(state) {
      state.tempNoteName = state.noteNameGlobal
    },
    setGraphicNameGlobal(state, action) {
      state.graphicNameGlobal = action.payload
    },
    setGraphicDescriptionGlobal(state, action) {
      state.graphicDescriptionGlobal = action.payload
    },
    setGraphicColorGlobal(state, action) {
      state.colorIdGlobal = action.payload
    },
    setShowNameInMapGlobal(state, action) {
      state.showNameInMapGlobal = action.payload
    },
    setNoteNameGlobal(state, action) {
      state.noteNameGlobal = action.payload
    },
    setGraphicsSelected(state, action) {
      state.graphicsSelected = action.payload
    },
    setCurrentNoteId(state, action) {
      state.currentNoteId = action.payload
    },

    updateNoteName(state, action) {
      const note = state.notes.find((note) => note.id === state.currentNoteId)
      if (note) {
        note.name = action.payload
      }
      state.notes = [...state.notes]
    },

    updateGraphicName(state, action) {
      const graphic = state.noteGraphics.find(
        (note) => note.objectId === action.payload.objectId,
      )
      if (graphic) {
        graphic.name = action.payload.name
      }
      state.noteGraphics = [...state.noteGraphics]
    },

    updateGraphicDescription(state, action) {
      let noteGraphicsCopy = [...state.noteGraphics]
      const graphic = noteGraphicsCopy.find(
        (note) => note.objectId === action.payload.objectId,
      )
      if (graphic) {
        graphic.description = action.payload.description
      }
      state.noteGraphics = [...noteGraphicsCopy]
    },

    /* updateGraphicColor(state, action) {
      let noteGraphicsCopy = [...state.noteGraphics]
      const graphic = noteGraphicsCopy.find(
        (note) => note.objectId === action.payload.objectId,
      )
      if (graphic) {
        graphic.colorId = action.payload.colorId
      }
      state.noteGraphics = [...noteGraphicsCopy]
    }, */

    updateObjectId(state, action) {
      let noteGraphicsCopy = [...state.noteGraphics]
      const graphic = noteGraphicsCopy.find(
        (note) => note.objectId === action.payload.oldObjectId,
      )
      if (graphic) {
        graphic.objectId = action.payload.newObjectId
      }
      state.noteGraphics = [...noteGraphicsCopy]
    },

    /* updateGraphicShowNameInMap(state, action) {
      let noteGraphicsCopy = [...state.noteGraphics]
      const graphic = noteGraphicsCopy.find(
        (note) => note.objectId === action.payload.objectId,
      )
      if (graphic) {
        graphic.showNameInMap = action.payload.showNameInMap
      }
      state.noteGraphics = [...noteGraphicsCopy]
    }, */

    setCurrentNoteIsNew(state, action) {
      state.currentNoteIsNew = action.payload
    },

    sortNotesByName(state) {
      state.notes.sort((a, b) => a.name.localeCompare(b.name))
    },

    sortNotesByNameReverse(state) {
      state.notes.sort((a, b) => b.name.localeCompare(a.name))
    },

    sortNotesByDate(state) {
      state.notes.sort((a, b) =>
        dateCompare(Date.parse(a.date), Date.parse(b.date)),
      )
    },

    sortNotesByDateReverse(state) {
      state.notes.sort((a, b) =>
        dateCompare(Date.parse(b.date), Date.parse(a.date)),
      )
    },
  },
})

export default notesSlice.reducer
export const {
  addNoteObject,
  removeNoteObject,
  setisDrawing,
  setColorIdGlobal,
  addNoteGraphic,
  removeNoteGraphic,
  setTempGraphics,
  revertGraphics,
  setTempNoteName,
  setGraphicNameGlobal,
  setGraphicDescriptionGlobal,
  setShowNameInMapGlobal,
  setGraphicColorGlobal,
  setNoteNameGlobal,
  setGraphicsSelected,
  setCurrentNoteId,
  updateNoteName,
  updateGraphicName,
  updateGraphicDescription,
  // updateGraphicColor,
  updateObjectId,
  // updateGraphicShowNameInMap,
  setCurrentNoteIsNew,
  sortNotesByName,
  sortNotesByNameReverse,
  sortNotesByDate,
  sortNotesByDateReverse,
} = notesSlice.actions
export type { NotesGlobalState }
export { colorIds }
