/* import { I18n } from "react-redux-i18n"
import { SidebarItems } from "../store/menuSlice"
import SideBarItem from "./SideBar/SideBarItem" */
import SideBarItemMenuMobile from "./SideBar/SideBarItemMenuMobile"
/* import StarIcon from "../assets/icons/star.svg?react"
import StarMobileIcon from "../assets/icons/star-mobile.svg?react"
import BookmarkIcon from "../assets/icons/bookmark.svg?react"
import BookmarkMobileIcon from "../assets/icons/bookmark-mobile.svg?react"
import UserIcon from "../assets/icons/user.svg?react"
import UserMobileIcon from "../assets/icons/user-mobile.svg?react" */
import "../styles/Bottombar.css"
import { styled } from "@mui/material"

const BottomBar = () => {
  const BottomBarDiv = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
  }))

  return (
    <BottomBarDiv className="bottom-bar">
      {/* <SideBarItem
        id={SidebarItems.BOOKMARKS}
        Icon={BookmarkIcon}
        IconMobile={BookmarkMobileIcon}
        text={I18n.t("sidebar.bookmarks.title")}
        mobileOnly
        isOnBottomBar
      />
      <SideBarItem
        id={SidebarItems.FAVORITES}
        Icon={StarIcon}
        IconMobile={StarMobileIcon}
        text={I18n.t("sidebar.favorites.title")}
        mobileOnly
        shownInMap
        isOnBottomBar
      />
      <SideBarItem
        id={SidebarItems.PROFILE}
        Icon={UserIcon}
        IconMobile={UserMobileIcon}
        text={I18n.t("sidebar.profile.title")}
        mobileOnly
        isOnBottomBar
      ></SideBarItem> */}
      <SideBarItemMenuMobile />
    </BottomBarDiv>
  )
}

export default BottomBar
