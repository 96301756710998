import { createSlice } from "@reduxjs/toolkit"

interface CheckedStatus {
  [key: string]: {
    [key: string]: boolean
  }
}

interface HMSKState {
  showReported: boolean
  showFavorites: boolean
  checkedStatus: CheckedStatus
  rangeStartDate: number | null
  rangeEndDate: number | null
}

const initialHMSKState: HMSKState = {
  showReported: false,
  showFavorites: false,
  rangeStartDate: new Date().getTime(),
  rangeEndDate: new Date().getTime(),

  checkedStatus: {
    severity: {
      low: false,
      medium: false,
      high: false,
    },
    process: {
      registered: false,
      processing: false,
      closed: false,
    },
    departmentManager: {},
    category: {
      hse: false,
      equipmentDefects: false,
      qualityDefects: false,
      qualityDeviations: false,
      improvementAndPositive: false,
      workEnvironment: false,
      machineControlError: false,
    },
    date: {
      last7days: false,
      last14days: false,
      last30days: false,
      chooseTimeSpan: false,
    },
  },
}

const HMSKSlice = createSlice({
  name: "hmsk",
  initialState: initialHMSKState,
  reducers: {
    toggle: (state, action) => {
      state.checkedStatus[action.payload.categoryName][
        action.payload.categoryItemName
      ] =
        !state.checkedStatus[action.payload.categoryName][
          action.payload.categoryItemName
        ]
    },
    addDepartmentManager: (state, action) => {
      state.checkedStatus.departmentManager[action.payload] = false
    },
    setShowReported: (state, action) => {
      state.showReported = action.payload
    },
    setShowFavorites: (state, action) => {
      state.showFavorites = action.payload
    },
    setRangeStartDate: (state, action) => {
      state.rangeStartDate = action.payload
    },
    setRangeEndDate: (state, action) => {
      state.rangeEndDate = action.payload
    },
  },
})

export default HMSKSlice.reducer
export const {
  toggle,
  addDepartmentManager,
  setShowReported,
  setShowFavorites,
  setRangeStartDate,
  setRangeEndDate,
} = HMSKSlice.actions

export type { HMSKState }
