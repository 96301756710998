import { createSlice } from "@reduxjs/toolkit"

interface AppState {
  locale: string
  allUserGroups: { [key: string]: string }
  darkmode: boolean
  loading: boolean
}

const allUserGroups: { [key: string]: string } = {
  1: "user.groups.machineOperator",
  2: "user.groups.safetyRepresentative",
  3: "user.groups.departmentManager",
  4: "user.groups.electrician",
  5: "user.groups.groundWork",
  6: "user.groups.truck",
  7: "user.groups.surveyor",
  8: "user.groups.unionRepresentative",
  9: "user.groups.tunnel",
  10: "user.groups.waterAndSewage",
}

const initialAppState = {
  locale: "nb",
  darkmode: false,
  loading: false,
}

const appSlice = createSlice({
  name: "app",
  initialState: initialAppState,
  reducers: {
    setLocale(state, action) {
      state.locale = action.payload
    },
    setDarkmode(state, action) {
      state.darkmode = action.payload
    },
    setLoading(state, action) {
      state.loading = action.payload
    }
  },
})

export default appSlice.reducer
export const { setLocale, setDarkmode, setLoading} = appSlice.actions
export type { AppState }
export { allUserGroups }
