import { Typography, IconButton, styled } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useDispatch } from "react-redux"
import { closeMenuItems, setVisibleMobile } from "../../../store/menuSlice"
import { MouseEventHandler } from "react"

const SubmenuTitle = (props: { text: String }) => {
  const { text } = props

  const dispatch = useDispatch()

  const SubmenuTitleDiv = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.secondary.light,
  }))

  return (
    <SubmenuTitleDiv className="sidebar-submenu-title-container">
      <div className="sidebar-submenu-title">
        <Typography variant="h3" sx={{ color: "text.primary" }}>
          {text}
        </Typography>
      </div>
      <div className="sidebar-submenu-close">
        <IconButton
          onClick={() => {
            dispatch(closeMenuItems())
            dispatch(setVisibleMobile(false))
          }}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </SubmenuTitleDiv>
  )
}

export default SubmenuTitle
