import { Typography, styled } from "@mui/material"
import "../styles/TopBar.css"
import { useContext, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { StoreState } from "../store/rootReducer"
/* import { setDarkmode } from "../store/appSlice"
import theme from "../styles/theme"
import DarkModeIcon from "@mui/icons-material/DarkMode" */
import { addSearchWidget } from "../map/MapWidgetFactory"
import ArcGISViewContext from "../context/ArcGISViewContext"
import DebugHighlightLandaxObjects from "../map/debugHighlightLandaxObjects"


const TopBar = () => {
  const searchDiv = useRef(null)
  const darkmode = useSelector((state: StoreState) => state.app.darkmode)
  const {activeView} = useContext(ArcGISViewContext)
  const dispatch = useDispatch()

  useEffect(() => {
    if (activeView && searchDiv.current) {
      addSearchWidget(activeView, searchDiv.current)
    }
  }, [activeView]);

  /* const handleToggleDarkmode = () => {
    dispatch(setDarkmode(!darkmode))
  } */

  const TopbarDiv = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.secondary.main,
  }))

  return (
    <TopbarDiv className="topbar">
      <Typography className="project-title" variant="h6"
        sx={{
          color: "background.default",
          minWidth: 0,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          marginLeft: "70px",
        }}
      >
        E10/Rv85 Hålogalandsveien: Gullesfjordbotn
      </Typography>
      <div className="searchbar-instance">
        <div id="searchbar" ref={searchDiv}></div>
      </div>
      {/* <Switch
        className="darkmode-toggle"
        onChange={handleToggleDarkmode}
        checked={darkmode}
        icon={<DarkModeIcon sx={{ fontSize: 14 }} />}
        checkedIcon={
          <DarkModeIcon sx={{ fontSize: 14, color: "background.default" }} />
        }
      /> */}

      <div className="left-div">
        <DebugHighlightLandaxObjects />
      </div>
    </TopbarDiv>
  )
}

export default TopBar
