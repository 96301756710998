import {
  Checkbox,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ZoomToLayerIcon from "../../../../assets/icons/layer-zoom-to.svg?react";
import appConfig from "../../../../config/AppConfig";
import { closeMenuItems, setVisibleMobile } from "../../../../store/menuSlice";
import {
  ProjectPlanningLeafItem,
  ProjectPlanningNodeType,
  selectLeafItem,
} from "../../../../store/projectPlanningSlice";
import { StoreState } from "../../../../store/rootReducer";
import theme from "../../../../styles/theme";
import { isValidExtent } from "../../../../map/MapUtils";

const LayerItemLeaf = (props: {
  metadataKeyAndtitle: string;
  projectPlanningMetadata: ProjectPlanningNodeType;
  parentKeys: string[];
  onZoomToLayer?: (extentString: string) => void;
}) => {
  const { metadataKeyAndtitle, projectPlanningMetadata, parentKeys, onZoomToLayer } = props;
  const dispatch = useDispatch();
  const darkmode = useSelector((state: StoreState) => state.app.darkmode);
  const isMobile = useMediaQuery(`max-width: ${appConfig.ui.maxMobileWidth}px`);

  const toggleVisibility = () => {
    dispatch(selectLeafItem({ parentKeys, leafKey: metadataKeyAndtitle }));
  };

  const LeafItemDiv = styled("div")(({ theme }) => ({
    paddingLeft: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginLeft: `${(parentKeys?.length ?? 0) * 15}px`,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  }));


  return (
    <LeafItemDiv onClick={() => toggleVisibility()}>
      <Checkbox
        name={metadataKeyAndtitle + Math.floor(Math.random() * 10000)}
        checked={(projectPlanningMetadata[metadataKeyAndtitle].node as ProjectPlanningLeafItem).checked}
      />
      <Typography
        sx={{
          color: "text.primary",
          minWidth: 0,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flex: 1,
        }}
      >
        {metadataKeyAndtitle}
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          columnGap: "10px",
          marginRight: "14px",
        }}
      >
        {projectPlanningMetadata[metadataKeyAndtitle].node.extent &&
          isValidExtent(projectPlanningMetadata[metadataKeyAndtitle].node.extent as string) &&
          onZoomToLayer && (
            <IconButton
              onClick={(event) => {
                onZoomToLayer(
                  (projectPlanningMetadata[metadataKeyAndtitle].node as ProjectPlanningLeafItem).extent
                );
                if (isMobile) {
                  dispatch(setVisibleMobile(false));
                  dispatch(closeMenuItems());
                }
                event.stopPropagation();
              }}
            >
              <ZoomToLayerIcon
                width={18}
                height={18}
                fill={theme(darkmode).palette.primary.main}
              />
            </IconButton>
          )}
      </div>
    </LeafItemDiv>
  );
};

export default LayerItemLeaf;
