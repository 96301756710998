import { Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { StoreState } from "../../store/rootReducer"
import { I18n } from "react-redux-i18n"

const SideBarTitle = () => {
  const expanded = useSelector((state: StoreState) => state.menu.expanded)

  return (
    <div className="sidebar-title">
      <Typography variant="h1" sx={{ color: "text.primary" }}>
        {expanded ? I18n.t("name") : <div>&nbsp;</div>}
      </Typography>
    </div>
  )
}

export default SideBarTitle
