import { useSelector } from "react-redux"
import LoadingIcon from "../assets/icons/loading-indicator.svg"
import { StoreState } from "../store/rootReducer"

const LoadingIndicator = () => {
  const size = 100
  const loading = useSelector((state: StoreState) => state.app.loading)

  return loading ? (
    <div
      className="loading-indicator"
      style={{
        position: "absolute",
        top: `calc(50% - ${size / 2}px)`,
        left: `calc(50% - ${size / 2}px)`,
        animation: "spin 2s linear infinite",
        opacity: 0.3,
        width: size,
        height: size,
        zIndex: 1000,
      }}
    >
      <img src={LoadingIcon} alt="Loading..." width={size} height={size} />
    </div>
  ) : null
}

export default LoadingIndicator
