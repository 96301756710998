import { createSlice } from "@reduxjs/toolkit"
import { StoreState } from "./rootReducer"

const BookmarkTypes = {
  MINE: 0,
  SHARED: 1,
  ARCHIVE: 2,
}

interface BookmarkItemState {
  id: number
  title: string
  isLocked: boolean
  categories: number[]
}

interface BookmarkState {
  bookmarks: BookmarkItemState[]
  newId: number
  activeCategory: number
}

const initialBookmarkState: BookmarkState = {
  activeCategory: BookmarkTypes.MINE,
  newId: 4,
  bookmarks: [
    {
      id: 0,
      title: "Infrastruktur - elektrisk",
      isLocked: true,
      categories: [BookmarkTypes.MINE],
    },
    {
      id: 1,
      title: "Plan uke 40",
      isLocked: true,
      categories: [BookmarkTypes.MINE],
    },
    {
      id: 2,
      title: "<Eget bokmerke>",
      isLocked: false,
      categories: [BookmarkTypes.MINE],
    },
    {
      id: 3,
      title: "<Enda et privat bokmerke>",
      isLocked: false,
      categories: [BookmarkTypes.MINE],
    },
  ],
}

const bookmarkSlice = createSlice({
  name: "bookmark",
  initialState: initialBookmarkState,
  reducers: {
    setActiveCategory(state, action) {
      state.activeCategory = action.payload
    },
    addBookmark(state, action) {
      state.bookmarks.push({
        id: state.newId++,
        title: action.payload.title,
        isLocked: false,
        categories: [BookmarkTypes.MINE],
      })
    },
    renameBookmark(state, action) {
      const bookmark = state.bookmarks.find(
        (bookmark) => bookmark.id === action.payload.id,
      )
      if (bookmark) {
        bookmark.title = action.payload.title
      }
    },
    deleteBookmark(state, action) {
      state.bookmarks = state.bookmarks.filter(
        (bookmark) => bookmark.id !== action.payload,
      )
    },
    shareBookmark(state, action) {
      const bookmark = state.bookmarks.find(
        (bookmark) => bookmark.id === action.payload.id,
      )
      if (bookmark) {
        if (
          action.payload.shared &&
          !bookmark.categories.includes(BookmarkTypes.SHARED)
        )
          bookmark.categories.push(BookmarkTypes.SHARED)
        // Groups should probably go to some backend!
      }
    },
    archiveBookmark(state, action) {
      const bookmark = state.bookmarks.find(
        (bookmark) => bookmark.id === action.payload,
      )
      if (bookmark) {
        bookmark.categories = [BookmarkTypes.ARCHIVE]
      }
    },
  },
})

const selectBookmarksByCategory = (state: StoreState) => {
  const bookmarks = state.bookmarks.bookmarks
  const activeCategory = state.bookmarks.activeCategory
  return bookmarks.filter((bookmark) =>
    bookmark.categories.includes(activeCategory),
  )
}

export default bookmarkSlice.reducer
export const {
  setActiveCategory,
  addBookmark,
  renameBookmark,
  deleteBookmark,
  shareBookmark,
  archiveBookmark,
} = bookmarkSlice.actions
export { BookmarkTypes, selectBookmarksByCategory }
export type { BookmarkState }
