import SideBarTitle from "./SideBarHeader";
import SideBarItem from "./SideBarItem";
import { /* Divider, */ styled } from "@mui/material";
// import MapIcon from "../../assets/icons/map.svg?react"
// import LayersIcon from "../../assets/icons/layers.svg?react"
import PackageIcon from "../../assets/icons/package.svg?react";
// import WarningIcon from "../../assets/icons/warning.svg?react"
import ImageIcon from "../../assets/icons/image.svg?react";
/* import DiggerIcon from "../../assets/icons/digger.svg?react"
import LayersEditableIcon from "../../assets/icons/layers-editable.svg?react"
import StarIcon from "../../assets/icons/star.svg?react"
import StarMobileIcon from "../../assets/icons/star-mobile.svg?react"
import BookmarkIcon from "../../assets/icons/bookmark.svg?react"
import BookmarkMobileIcon from "../../assets/icons/bookmark-mobile.svg?react" */
import UserIcon from "../../assets/icons/user.svg?react";
/* import UserMobileIcon from "../../assets/icons/user-mobile.svg?react"
import SettingsIcon from "../../assets/icons/settings.svg"
import SubmenuHMSK from "./Submenus/HMSK/HMSK" */
import "../../styles/Sidebar.css";
import { SidebarItems } from "../../store/menuSlice";
import { useSelector } from "react-redux";
import { StoreState } from "../../store/rootReducer";
// import SubmenuBookmarks from "./Submenus/Bookmarks/Bookmarks"
import { I18n } from "react-redux-i18n";
import SubmenuProfile from "./Submenus/Profile/Profile";
// import SubmenuBasemaps from "./Submenus/Basemaps/Basemaps"
import SubmenuProjectPlanning from "./Submenus/ProjectPlanning/ProjectPlanning";
import SubmenuMedia from "./Submenus/Media/Media";
/* import SubmenuMachines from "./Submenus/Machines/Machines"
import SubmenuNotes from "./Submenus/Notes/Notes"
import SubmenuSettings from "./Submenus/Settings/Settings"
import SubmenuFavorites from "./Submenus/Favorites/Favorites"
import NewNote from "./Submenus/Notes/NewNote" */

const SideBar = () => {
  const expanded = useSelector((state: StoreState) => state.menu.expanded);
  const isVisibleMobile = useSelector(
    (state: StoreState) => state.menu.visibleMobile
  );

  const SidebarDiv = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.background.default,
    borderRight: `1px solid ${theme.palette.divider}`,
  }));

  return (
    <SidebarDiv
      className={`sidebar${expanded ? " expanded" : ""}${
        isVisibleMobile ? "" : " sidebar-mobile-hidden"
      }`}
    >
      <SideBarTitle />
      <div className="sidebar-top">
        {/* <SideBarItem
          id={SidebarItems.RESETMAP}
          Icon={MapIcon}
          text={I18n.t("sidebar.resetMap.title")}
          desktopOnly
        />
        <Divider className="sidebar-divider sidebar-item-desktop-only" />
        <SideBarItem
          id={SidebarItems.BASEMAPS}
          Icon={LayersIcon}
          text={I18n.t("sidebar.basemaps.title")}
          shownInMap
        >
          <SubmenuBasemaps />
        </SideBarItem>*/}
        <SideBarItem
          id={SidebarItems.PROJECTPLANNING}
          Icon={PackageIcon}
          text={I18n.t("sidebar.projectPlanning.title")}
          shownInMap
        >
          <SubmenuProjectPlanning />
        </SideBarItem>
        {/* <SideBarItem
          id={SidebarItems.HSEQ}
          Icon={WarningIcon}
          text={I18n.t("sidebar.hseq.title")}
          shownInMap
        >
          <SubmenuHMSK />
        </SideBarItem>  */}
        <SideBarItem
          id={SidebarItems.MEDIA}
          Icon={ImageIcon}
          text={I18n.t("sidebar.media.title")}
          shownInMap
        >
          <SubmenuMedia />
        </SideBarItem>
        {/* <SideBarItem
          id={SidebarItems.MACHINES}
          Icon={DiggerIcon}
          text={I18n.t("sidebar.machines.title")}
          shownInMap
        >
          <SubmenuMachines />
        </SideBarItem> */}
      </div>
      <div className="sidebar-space"></div>
      <div className="sidebar-bottom mobile-bottom-desktop">
        {/* <Divider className="sidebar-divider" />
        <SideBarItem
          id={SidebarItems.DRAWMAP}
          Icon={LayersEditableIcon}
          text={I18n.t("sidebar.notes.title")}
        >
          <SubmenuNotes />
        </SideBarItem>
        <SideBarItem
          id={SidebarItems.BOOKMARKS}
          Icon={BookmarkIcon}
          // IconMobile={BookmarkMobileIcon}
          text={I18n.t("sidebar.bookmarks.title")}
          desktopOnly
        >
          <SubmenuBookmarks />
        </SideBarItem>
        <SideBarItem
          id={SidebarItems.FAVORITES}
          Icon={StarIcon}
          // IconMobile={StarMobileIcon}
          text={I18n.t("sidebar.favorites.title")}
          desktopOnly
          shownInMap
        >
          <SubmenuFavorites />
        </SideBarItem> */}
        <SideBarItem
          id={SidebarItems.PROFILE}
          Icon={UserIcon}
          // IconMobile={UserMobileIcon}
          text={I18n.t("sidebar.profile.title")}
          desktopOnly
        >
          <SubmenuProfile />
        </SideBarItem>
        {/* <SideBarItem
          id={SidebarItems.SETTINGS}
          Icon={SettingsIcon}
          text={I18n.t("sidebar.settings.title")}
        >
          <SubmenuSettings />
        </SideBarItem> */}
      </div>
    </SidebarDiv>
  );
};

export default SideBar;
