const translations = {
  nb: {
    name: "HæhreGIS",
    buttons: {
      save: "Lagre",
      cancel: "Avbryt",
      yes: "Ja",
      no: "Nei",
    },
    shared: {
      loading: "Henter data...",
      loadingServices: "Laster kart...",
      nodata: "Ingen data.",
      error: "Noe gikk galt!.."
    },
    topbar: {
      search: "Søk etter prosjekt, steder og adresser",
    },
    user: {
      groups: {
        machineOperator: "Maskinfører",
        safetyRepresentative: "Verneombud",
        departmentManager: "Avdelingsleder",
        electrician: "Elektrisk",
        groundWork: "Grunnarbeid",
        truck: "Lastebil",
        surveyor: "Stikker",
        unionRepresentative: "Tillitsvalgt",
        tunnel: "Tunnel",
        waterAndSewage: "VA",
      },
      employeeTypes: {
        employee: "Ansatt",
        subcontractor: "Underleverandør",
      },
      departments: {
        constructionMachines: "Anleggsmaskiner",
      },
      salaryTypes: {
        hourly: "Timelønn",
      },
      accessLevels: {
        admin: "Admin",
        user: "Bruker",
      },
    },
    sidebar: {
      resetMap: { title: "Tilbakestill kart" },
      basemaps: { title: "Kartgrunnlag" },
      projectPlanning: {
        title: "Prosjektering",
        not2DSupported: "BIM vises kun i 3D",
        wbs: {
          step1: "WBS 1",
          step2: "WBS 2",
          step3: "WBS 3",
          step4: "WBS 4",
          step5: "WBS 5",
        },
        status: {
          workingBasis: "Arbeidsgrunnlag",
          elevation: "Terrengmodell",
        },
      },
      hseq: {
        title: "HMSK",
        showReportedDeviations: "Vis rapporterte avvik",

        groups: {
          severity: {
            header: "Alvorlighetsgrad",
            low: "Lav",
            medium: "Medium",
            high: "Høy",
          },
          process: {
            header: "Prosess",
            registered: "Registrert",
            processing: "Under behandling",
            closed: "Lukket",
          },
          departmentManager: { header: "Avd.leder" },
          category: {
            header: "Kategori",
            hse: "HMS- og YM-hendelser",
            equipmentDefects: "Feil på utstyr og maskiner",
            qualityDefects: "Kvalitetsfeil",
            qualityDeviations: "Kvalitetsavvik",
            improvementAndPositive: "Forbedringsforslag og positive hendelser",
            workEnvironment: "Arbeidsmiljø",
            machineControlError: "Maskinstyringsfeil",
          },
          date: {
            header: "Dato for hendelse",
            last7days: "Siste 7 dager",
            last14days: "Siste 14 dager",
            last30days: "Siste 30 dager",
            chooseTimeSpan: "Velg tidsrom",
            from: "Fra",
            to: "Til",
          },
        },
        caseNumber: "Saksnummer",
      },
      media: {
        title: "Bilder og media",
        drone: "Dronebilder",
      },
      machines: { title: "Maskiner" },
      notes: {
        title: "Notater",
        addNote: "Opprett nytt notat",
        newNote: "Nytt notat",
        objectName: "Objektnavn/tekst:",
        showInMap: "Vis i kartet",
        comment: "Kommentar (vises ved klikk på objekt)",
        noteName: "Notatnavn:",
        noName: "<Ingen navn>",
        noDescription: "<Ingen beskrivelse>",
        description1: `Du kan legge til flere figurer og tegninger, for så å lagre alt
          samlet. Notater vil lagres som egne kartlag, og er i utgangspunktet
          kun synlig for deg.`,
        description2: `Vis notatet i riktig sammenheng, ved å opprette et bokmerke. Dette kan
          du dele med andre.`,
        myNotes: "Mine notater",
        deleteNote: "Slett notat",
        saveNote: "Lagre notat",
        cancelNote: "Avbryt notat",
      },
      favorites: { title: "Favoritter" },
      bookmarks: {
        title: "Lagrede visninger",
        info: "Her kan du lagre kart med ulike innstillinger, så du raskt kan bytte mellom forskjellige visninger.",
        types: {
          mine: "Mine",
          shared: "Felles",
          archive: "Arkiv",
        },
        typeHeaders: {
          mine: "Mine bokmerker",
          shared: "Felles bokmerker",
          archive: "Arkiv",
        },
        create: {
          header: "Opprett bokmerke",
          label: "Gi bokmerket et navn.",
          info1:
            "Bokmerket vil ha de samme instillingene og kartlagene som du har nå.",
          info2:
            "Bokmerket vil kun være synlig for deg. Du kan i ettertid dele det med andre.",
        },
        contextMenu: {
          rename: "Gi nytt navn",
          share: "Del med gruppe",
          archive: "Flytt til arkiv",
          delete: "Slett",
        },
        rename: {
          title: "Endre navn",
        },
        share: {
          shareToAllPrompt: 'Skal bokmerket vises som "Felles"?',
          info: 'Velg hvilke grupper som skal ha bokmerket under "Mine".',
        },
        archive: {
          prompt: "Vil du flytte dette bokmerket til arkiv?",
          info: "Det vil arkiveres for alle brukere med tilgang til bokmerket.",
        },
        delete: {
          prompt: "Vil du slette dette bokmerket?",
          info: "Bokmerket vil slettes for alle brukere med tilgang til bokmerket.",
        },
      },
      profile: {
        title: "Profil",
        details: {
          employeeNumber: "Ansattnummer",
          department: "Avdeling",
          salaryType: "Lønn",
          accessLevel: "Tilgangsnivå",
          groups: "Grupper",
          company: "Firma",
        },
        login: "Logg inn",
        logout: "Logg ut",
        employeeTypes: {
          employee: "Ansatt",
          subcontractor: "Underleverandør",
        },
      },
      settings: {
        title: "Innstillinger",
      },
    },
  },
  /*en: {
    buttons: {
      save: "Save",
      cancel: "Cancel",
      yes: "Yes",
      no: "No",
    },
    topbar: {
      search: "Search for projects, places and addresses",
    },
    user: {
      groups: {
        machineOperator: "Machine operator",
        safetyRepresentative: "Safety representative",
        departmentManager: "Department manager",
        electrician: "Electrician",
        groundWork: "Ground work",
        truck: "Truck",
        surveyor: "Surveyor",
        unionRepresentative: "Union representative",
        tunnel: "Tunnel",
        waterAndSewage: "Water and sewage",
      },
      employeeTypes: {
        employee: "Employee",
        subcontractor: "Subcontractor",
      },
      departments: {
        constructionMachines: "Construction machines",
      },
      salaryTypes: {
        hourly: "Hourly",
      },
      accessLevels: {
        admin: "Admin",
        user: "User",
      },
    },
    sidebar: {
      resetMap: { title: "Reset map" },
      basemaps: { title: "Base maps" },
      projectPlanning: { title: "Project planning" },
      hseq: {
        title: "HSEQ",
        showReportedDeviations: "Show reported deviations",

        groups: {
          severity: {
            header: "Severity",
            low: "Low",
            medium: "Medium",
            high: "High",
          },
          process: {
            header: "Process",
            registered: "Registered",
            processing: "Processing",
            closed: "Closed",
          },
          departmentManager: { header: "Dept. manager" },
          category: {
            header: "Category",
            hse: "HSE and EM incidents",
            equipmentDefects: "Defects in equipment and machines",
            qualityDefects: "Quality defects",
            qualityDeviations: "Quality deviations",
            improvementAndPositive:
              "Suggestions for improvements and positive events",
            workEnvironment: "Work environment",
            machineControlError: "Machine control error",
          },
          date: {
            header: "Incident date",
            last7days: "Last 7 days",
            last14days: "Last 14 days",
            last30days: "Last 30 days",
            chooseTimeSpan: "Choose time span",
            from: "From",
            to: "To",
          },
        },
      },
      media: { title: "Images and media" },
      machines: { title: "Machines" },
      drawMap: { title: "Add / draw in map" },
      favorites: { title: "Favorites" },
      bookmarks: {
        title: "Saved views",
        info: "Here you can save maps with different settings, so you can quickly switch between different views.",
        types: {
          mine: "Mine",
          shared: "Shared",
          archive: "Archive",
        },
        typeHeaders: {
          mine: "My bookmarks",
          shared: "Shared bookmarks",
          archive: "Archive",
        },
        create: {
          header: "Create bookmark",
          label: "Give the bookmark a name.",
          info1:
            "The bookmark will have the same settings and map layers as you have now.",
          info2:
            "The bookmark will only be visible to you. You can share it with others later.",
        },
        contextMenu: {
          rename: "Rename",
          share: "Share with group",
          archive: "Move to archive",
          delete: "Delete",
        },
        rename: {
          title: "Rename",
        },
        share: {
          shareToAllPrompt: 'Will the bookmark be shown as "Shared"?',
          info: 'Choose which groups should have the bookmark under "Mine".',
        },
        archive: {
          prompt: "Do you want to move this bookmark to the archive?",
          info: "It will be archived for all users with access to the bookmark.",
        },
        delete: {
          prompt: "Do you want to delete this bookmark?",
          info: "The bookmark will be deleted for all users with access to the bookmark.",
        },
      },
      profile: {
        title: "Profile",
        details: {
          employeeNumber: "Employee number",
          department: "Department",
          salaryType: "Salary",
          accessLevel: "Access level",
          groups: "Groups",
          company: "Company",
        },
        login: "Log in",
        logout: "Log out",
      },
    },
  },*/
};

export default translations;
