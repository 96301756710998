import ZoomToLayerIcon from "../../assets/icons/layer-zoom-to.svg?react";
import {
  Checkbox,
  IconButton,
  styled,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { StoreState } from "../../store/rootReducer";
import theme from "../../styles/theme";
import { useState } from "react";
import Layer from "@arcgis/core/layers/Layer";
import WMSSublayer from "@arcgis/core/layers/support/WMSSublayer";
import appConfig from "../../config/AppConfig";
import { closeMenuItems, setVisibleMobile } from "../../store/menuSlice";

const LayerListItem = (props: {
  layer: Layer | WMSSublayer;
  onZoomToLayer?: (layer: Layer) => void;
  onLayerClick?: (layer: Layer) => void;
}) => {
  const { layer, onZoomToLayer, onLayerClick } = props;
  const [visible, setVisible] = useState(layer.visible ?? false);
  const darkmode = useSelector((state: StoreState) => state.app.darkmode);
  const isMobile = useMediaQuery(`max-width: ${appConfig.ui.maxMobileWidth}px`);
  const dispatch = useDispatch();

  const toggleVisibility = () => {
    if (onLayerClick) {
      onLayerClick(layer as Layer);
    }
    setVisible(!visible);
    layer.visible = !visible;
  };

  const TitleDiv = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 15px 5px 8px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  }));

  return (
    <TitleDiv
      title={(layer as any).name ?? layer.title ?? layer.id ?? "ukjent navn"}
      onClick={() => {
        toggleVisibility();
      }}
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        /* backgroundColor: visible
          ? theme(darkmode).palette.secondary.dark
          : "transparent",
        borderLeft: visible
          ? `3px solid ${theme(darkmode).palette.primary.main}`
          : "none", */
        padding: "5px 15px 5px 8px",
        cursor: "pointer",
      }}
    >
      <Checkbox checked={visible} onChange={() => toggleVisibility()} />
      <Typography
        sx={{
          color: "text.primary",
          minWidth: 0,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          flex: 1,
        }}
      >
        {(layer as any).name ?? layer.title ?? layer.id ?? "ukjent navn"}
      </Typography>
      <div style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}>
        {layer.fullExtent && onZoomToLayer && (
          <IconButton
            onClick={(event) => {
              if (isMobile) {
                dispatch(setVisibleMobile(false));
                dispatch(closeMenuItems());
              }
              onZoomToLayer(layer as Layer);
              event.stopPropagation();
            }}
          >
            <ZoomToLayerIcon
              width={18}
              height={18}
              fill={theme(darkmode).palette.primary.main}
            />
          </IconButton>
        )}
      </div>
    </TitleDiv>
  );
};

export default LayerListItem;
