import { Chip, Typography } from "@mui/material";
import { Translate } from "react-redux-i18n";
import { allUserGroups } from "../../../../store/appSlice";
import { useSelector } from "react-redux";
import { StoreState } from "../../../../store/rootReducer";
import { getProfile } from "../../../../store/profileSlice";

const ProfileGroups = (props: { profileId: number }) => {
  const { profileId } = props;

  const profile = getProfile(
    useSelector((state: StoreState) => state.profile.profiles),
    profileId
  );

  return (
    <tr>
      <td
        style={{
          verticalAlign: "top",
          paddingRight: "15px",
          paddingTop: "7px",
        }}
      >
        <Typography sx={{ color: "text.secondary" }}>
          <Translate value="sidebar.profile.details.groups" />
        </Typography>
      </td>
      <td>
        {profile?.groups.map((group) => (
          <Chip
            key={group}
            className="user-group"
            label={<Translate value={allUserGroups[group.toString()]} />}
          />
        ))}
      </td>
    </tr>
  );
};

export default ProfileGroups;
