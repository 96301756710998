import { Typography } from "@mui/material"
import { Translate } from "react-redux-i18n"
import { getProfile } from "../../../../store/profileSlice"
import { StoreState } from "../../../../store/rootReducer"
import { useSelector } from "react-redux"

const ProfileEmployeeType = (props: { profileId: number }) => {
  const { profileId } = props

  const profile = getProfile(
    useSelector((state: StoreState) => state.profile.profiles),
    profileId,
  )

  return (
    <Typography sx={{ color: "text.primary", fontWeight: "bold" }}>
      <Translate value={profile?.employeeType ?? ""} />
    </Typography>
  )
}

export default ProfileEmployeeType
