import { Extent } from "@arcgis/core/geometry";
import { ServiceType } from "../config/AppConfig";


export const zoomToExtent = (view: __esri.MapView | __esri.SceneView, extentString: string) => {
  if (!extentString) return;
  if (!isValidExtent(extentString)) return;

  const [xmin, ymin, xmax, ymax] = extentString
    .split(",")
    .map((num) => parseFloat(num));

  const extent = new Extent({
    xmin,
    ymin,
    xmax,
    ymax,
    spatialReference: view.spatialReference,
  });

  view.goTo(extent);
};

export const addLayerToMap = (
  layer: __esri.Layer,
  activeView: __esri.MapView | __esri.SceneView
) => {
  let layers: __esri.Collection<__esri.Layer>;
  if (layer.type === ServiceType.ElevationLayer) {
    layers = activeView.map.ground.layers;
  } else {
    layers = activeView.map.layers;
  }

  const layerIds = layers.map((layer) => layer.id);
  if (!layerIds.includes(layer.id)) {
    layers.add(layer);
  }
};

export const isValidExtent = (extent: string) => {
  const [xmin, ymin, xmax, ymax] = extent
    .split(",")
    .map((num) => parseFloat(num));
  return !isNaN(xmin) && !isNaN(ymin) && !isNaN(xmax) && !isNaN(ymax);
};
