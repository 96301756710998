import { useContext, useEffect, useState } from "react";
import Icon2D from "../../assets/icons/2d-icon.svg?react";
import Icon3D from "../../assets/icons/3d-icon.svg?react";
import ArcGISViewContext from "../../context/ArcGISViewContext";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";
import { createPortal } from "react-dom";

const ViewToggleButton = (props: { container: any }) => {
  const mapDivElement = props.container;
  const { mapView, activeView, setActiveView, sceneView } =
    useContext(ArcGISViewContext);
  const [is3D, setIs3D] = useState(activeView ? activeView.type === "3d" : false);
  const [viewReady, setViewReady] = useState(false);
  const [container, setContainer] = useState<Element | null>(null);

  const handleClick = () => {
    if (mapDivElement.current == null) return;

    const activeViewpoint = activeView.viewpoint.clone();
    // remove the reference to the container for the previous view
    (activeView.container as any) = null;

    if (is3D) {
      // if the input view is a SceneView, set the viewpoint on the
      // mapView instance. Set the container on the mapView and flag
      // it as the active view
      mapView.viewpoint = activeViewpoint;
      (mapView.container as any) = mapDivElement.current;
      setActiveView(mapView);
    } else {
      sceneView.viewpoint = activeViewpoint;
      (sceneView.container as any) = mapDivElement.current;
      setActiveView(sceneView);
    }
    setIs3D(!is3D);
  };

  useEffect(() => {
    if (activeView) {
      reactiveUtils.whenOnce(
        () => activeView.ready)
        .then(() => {
          setViewReady(true);
          const element = document.querySelector(".esri-ui-bottom-right");
          //const element = 
          setContainer(element);
        });
    }
  }, [activeView]);

  return (
    <div>
      {container && createPortal(
        <ViewToggleButtonComponent handleClick={handleClick} viewReady={viewReady} is3D={is3D} />,
        container
      )}
    </div>
  );
};

export default ViewToggleButton;


const ViewToggleButtonComponent = (props: { handleClick: any, viewReady: boolean, is3D: boolean }) => {
  const { handleClick, viewReady, is3D } = props;
  return (
    <div>
      <button
        type="button"
        onClick={handleClick}
        disabled={!viewReady}
        className="esri-widget--button esri-widget esri-component"
        style={{
          position: "relative",
          bottom: "2.6rem",
          right: "-4.5rem",
          opacity: 0.4,
          width: "3rem",
          height: "3rem",
          borderRadius: "50%",
          backgroundColor:"#FFF",
        }}
      >
        {is3D ?
          <Icon2D /> : <Icon3D />}
      </button>
    </div>
  );
};
