import { configureStore } from "@reduxjs/toolkit"
import rootReducer from "./rootReducer"
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from "react-redux-i18n"
import translations from "../i18n/translations"

const store = configureStore({
  reducer: rootReducer,
})

syncTranslationWithStore(store)
store.dispatch(loadTranslations(translations))
store.dispatch(setLocale(store.getState().app.locale))

export type AppDispatch = typeof store.dispatch

export default store
