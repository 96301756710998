import { Paper } from "@mui/material"

const Submenu = (props: { children?: React.ReactNode; className?: string }) => {
  return (
    <Paper
      elevation={0}
      sx={{ backgroundColor: "background.default" }}
      className={`sidebar-submenu${
        props.className ? ` ${props.className}` : ""
      }`}
    >
      {props.children}
    </Paper>
  )
}

export default Submenu
