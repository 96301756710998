import ExpandCollapse from "./ExpandCollapse"
import SideBarTitle from "./SidebarTitle"

const SideBarHeader = () => {
  return (
    <div className="sidebar-header">
      <SideBarTitle />
      <ExpandCollapse />
    </div>
  )
}

export default SideBarHeader
