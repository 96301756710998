import MenuIconActive from "../../assets/icons/menu.svg?react";
import { useDispatch, useSelector } from "react-redux";
import {
  SidebarItems,
  setActiveMenuItem,
  toggleVisibleMobile,
} from "../../store/menuSlice";
import { StoreState } from "../../store/rootReducer";
import { styled } from "@mui/material";
import theme from "../../styles/theme";

const SideBarItemMenuMobile = () => {
  const isVisibleMobile = useSelector(
    (state: StoreState) => state.menu.visibleMobile
  );
  const darkmode = useSelector((state: StoreState) => state.app.darkmode);
  const dispatch = useDispatch();

  const SidebarItemMenuMobileDiv = styled("div")(({ theme }) => ({
    backgroundColor: isVisibleMobile ? theme.palette.primary.light : "none",
  }));

  const iconFill = isVisibleMobile
    ? theme(darkmode).palette.secondary.main
    : theme(true).palette.primary.main;

  return (
    <SidebarItemMenuMobileDiv
      className={`sidebar-item sidebar-item-mobile-only${
        isVisibleMobile ? " sidebar-item-active" : ""
      }`}
      onClick={() => {
        dispatch(toggleVisibleMobile());
        if (isVisibleMobile) {
          dispatch(setActiveMenuItem(SidebarItems.NONE));
        } else {
          dispatch(setActiveMenuItem(SidebarItems.BASEMAPS));
        }
      }}
    >
      <MenuIconActive fill={iconFill} />
    </SidebarItemMenuMobileDiv>
  );
};

export default SideBarItemMenuMobile;
