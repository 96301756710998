import { Avatar, Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { StoreState } from "../../../../store/rootReducer"
import { Translate } from "react-redux-i18n"
import { getProfile } from "../../../../store/profileSlice"

const ProfileAvatar = (props: { profileId: number }) => {
  const { profileId } = props

  const profile = getProfile(
    useSelector((state: StoreState) => state.profile.profiles),
    profileId,
  )

  return (
    <div className="profile-avatar">
      <Avatar className="profile-avatar-image" src={profile?.imgPath} />
      <div className="profile-avatar-details">
        <span className="profile-avatar-name">
          <Typography sx={{ color: "text.primary" }}>
            {profile?.name}
          </Typography>
        </span>
        <span className="profile-avatar-title">
          <Typography sx={{ color: "text.secondary" }}>
            <Translate value={profile?.title ?? ""} />
          </Typography>
        </span>
      </div>
    </div>
  )
}

export default ProfileAvatar
