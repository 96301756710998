import { styled } from "@mui/material"

const LayerVisibleIndicator = (props: { expanded?: boolean }) => {
  const { expanded } = props

  const LayerVisibleIndicatorDiv = styled("div")(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
  }))

  return (
    <LayerVisibleIndicatorDiv
      className={`layer-visible-indicator ${
        expanded ? "expanded" : "contracted"
      }`}
    ></LayerVisibleIndicatorDiv>
  )
}

export default LayerVisibleIndicator
