import ProfileContactItem from "./ProfileContactItem"
import PhoneIcon from "../../../../assets/icons/phone.svg?react"
import EmailIcon from "../../../../assets/icons/email.svg?react"
import { useSelector } from "react-redux"
import { StoreState } from "../../../../store/rootReducer"
import { getProfile } from "../../../../store/profileSlice"

const ProfileContact = (props: { profileId: number }) => {
  const { profileId } = props

  const profile = getProfile(
    useSelector((state: StoreState) => state.profile.profiles),
    profileId,
  )

  return (
    <>
      <ProfileContactItem Icon={PhoneIcon} value={profile?.phone} />
      <ProfileContactItem Icon={EmailIcon} value={profile?.email} />
    </>
  )
}

export default ProfileContact
