import { ThemeProvider } from "@mui/material";
import MapComponent from "./components/MapComponent";
import SideBar from "./components/SideBar/SideBar";
import TopBar from "./components/TopBar";
import "./styles/Calendar.css";
import theme from "./styles/theme";
import { Provider, useDispatch, useSelector } from "react-redux";
import store from "./store/storeSetup";
import { StoreState } from "./store/rootReducer";
import BottomBar from "./components/BottomBar";
import { useEffect, useState } from "react";
import esriId from "@arcgis/core/identity/IdentityManager.js";
import esriConfig from "@arcgis/core/config";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo.js";
import esriRequest from "@arcgis/core/request.js";
import appConfig from "./config/AppConfig";
import ArcGISContext from "./context/ArcGISViewContext";
import {
  accessLevels,
  addProfile,
  setCurrentProfile,
} from "./store/profileSlice";

function App() {
  const darkmode = useSelector((state: StoreState) => state.app.darkmode);
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState(false);
  // view context
  const [activeView, setActiveView] = useState(
    null as unknown as __esri.MapView | __esri.SceneView
  );
  const [mapView, setMapView] = useState(null as unknown as __esri.MapView);
  const [sceneView, setSceneView] = useState(
    null as unknown as __esri.SceneView
  );

  useEffect(() => {
    // set default portal url at init load
    esriConfig.portalUrl = appConfig.app.portalUrl;

    const info = new OAuthInfo({
      appId: appConfig.app.clientSecret,
      portalUrl: appConfig.app.portalUrl,
      popup: false,
    });
    esriId.registerOAuthInfos([info]);

    esriId
      .checkSignInStatus(appConfig.app.portalUrl + "/sharing")
      .then((credentials) => {
        // get info from AGEK portal user, to be replaced with Ditio info
        return esriRequest(
          `${esriConfig.portalUrl}/sharing/rest/community/users/${credentials.userId}`,
          {
            query: {
              f: "json",
              token: credentials.token,
            },
          }
        ).then((user) => {
          return { user, credentials };
        });
      })
      .then(({ user, credentials }) => {
        dispatch(
          addProfile({
            id: 0,
            name: user.data?.fullName,
            title: "Title: to be implemented from Ditio", // titles.TRUCKDRIVER
            imgPath: user.data?.thumbnail
              ? `${esriConfig.portalUrl}/sharing/rest/community/users/${credentials.userId}/info/blob.png?token=${credentials.token}`
              : null,
            phone: "Phone: to be implemented from Ditio",
            email: user.data?.email,
            employeeType: "Type: to be implemented from Ditio",
            accessLevel:
              user.data?.role === "org_admin"
                ? accessLevels.ADMIN
                : accessLevels.USER,
            groups: [],
            company: "Company: to be implemented from Ditio",
          })
        );

        dispatch(
          setCurrentProfile({
            accessLevel:
              user.data?.role === "org_admin"
                ? accessLevels.ADMIN
                : accessLevels.USER,
            currentProfileId: 0,
          })
        );

        setLoggedIn(true);
      })
      .catch((error) => {
        console.log("Error: ", error);
        esriId.getCredential(info.portalUrl + "/sharing"); // => trigger sign-in
      });
  }, []);

  return loggedIn ? (
    <ThemeProvider theme={theme(darkmode)}>
      <ArcGISContext.Provider
        value={{
          activeView,
          setActiveView,
          mapView,
          setMapView,
          sceneView,
          setSceneView,
        }}
      >
        <Provider store={store}>
          <div className="App" style={{ height: "100%", width: "100%" }}>
            <TopBar />
            <SideBar />
            <MapComponent />
            <BottomBar />
          </div>
        </Provider>
      </ArcGISContext.Provider>
    </ThemeProvider>
  ) : (
    <div>Logger inn...</div>
  );
}

export default App;
