import { Dispatch, SetStateAction, createContext } from 'react';

/* ArcGIS View Context
*   Views are stored in Context for easy access in components
*   Storing Views in React Toolkit store is not recommended, as object are to be serialized
*/
const ArcGISViewContext = createContext<{
    mapView: __esri.MapView, 
    setMapView: Dispatch<SetStateAction<__esri.MapView>>,
    sceneView: __esri.SceneView, 
    setSceneView: Dispatch<SetStateAction<__esri.SceneView>>,
    activeView: __esri.MapView | __esri.SceneView, 
    setActiveView: Dispatch<SetStateAction<__esri.MapView | __esri.SceneView | any>>,

  }>({
    mapView: null as unknown as __esri.MapView, 
    setMapView: () => {},
    sceneView: null as unknown as __esri.SceneView, 
    setSceneView: () => {},
    activeView: null as unknown as __esri.MapView | unknown as __esri.SceneView, 
    setActiveView: () => {},
  });

export default ArcGISViewContext;