import { ProjectPlanningNodeType } from "../../../../store/projectPlanningSlice";
import LayerItemCollapsibleMenu from "./LayerItemCollapsibleMenu";
import LayerItemLeaf from "./LayerItemLeaf";
import LayerItemCollapsiblePartition from "./LayerItemPartition";

const NestedLayerItem = (props: {
  projectPlanningMetadata: ProjectPlanningNodeType;
  parentKeys: string[]; // keep track of where in tree we are
  onZoomToLayer?: (extentString: string) => void;
}) => {
  const { projectPlanningMetadata, parentKeys, onZoomToLayer } = props;

  return Object.keys(projectPlanningMetadata).map((keyAttribute) => {
    const nodeData = projectPlanningMetadata[keyAttribute];
    if (nodeData.node.hasOwnProperty("checked")) {
      return (
        <LayerItemLeaf
          key={parentKeys?.join(".") + keyAttribute} //unique key
          metadataKeyAndtitle={keyAttribute}
          projectPlanningMetadata={projectPlanningMetadata}
          parentKeys={parentKeys} // keep track of where in tree we are
          onZoomToLayer={onZoomToLayer}
        />
      );
    }
    else if (nodeData.hasOwnProperty("sceneItemId")) {
      return (
        <LayerItemCollapsiblePartition
          key={parentKeys?.join(".") + keyAttribute} //unique key
          metadataKeyAndtitle={keyAttribute}
          projectPlanningMetadata={projectPlanningMetadata}
          parentKeys={parentKeys} // keep track of where in tree we are
        />
      );
    }
    else {
      return (
        <LayerItemCollapsibleMenu
          key={parentKeys?.join(".") + keyAttribute} //unique key
          projectPlanningMetadata={projectPlanningMetadata}
          metadataKeyAndtitle={keyAttribute}
          parentKeys={parentKeys} // keep track of where in tree we are
          onZoomToLayer={onZoomToLayer}
        />
      );
    }
  });
};

export default NestedLayerItem;
