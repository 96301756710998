import { expandCollapseMenu, getAllLeafItemsWithSameItemId, ProjectPlanningNodeType, selectAllLeafItems } from "../../../../store/projectPlanningSlice";
import { useContext, useEffect } from "react";
import ArcGISViewContext from "../../../../context/ArcGISViewContext";
import { zoomToExtent } from "../../../../map/MapUtils";
import useProjectPlanningTreeCheckStatus from "../../../../hooks/useProjectPlanningTreeCheckStatus";
import { useDispatch, useSelector } from "react-redux";
import { Card, Checkbox, Collapse, styled, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import NestedLayerItem from "./NestedLayerItem";
import { StoreState } from "../../../../store/rootReducer";


const LayerItemCollapsiblePartition = (props: {
  projectPlanningMetadata: ProjectPlanningNodeType;
  metadataKeyAndtitle: string;
  parentKeys: string[];
}) => {
  const { projectPlanningMetadata, metadataKeyAndtitle, parentKeys } = props;
  const hasCheckbox = parentKeys.length >= 2;
  const nodeData = projectPlanningMetadata[metadataKeyAndtitle];
  const mapLayerId = (nodeData as any).outlineItemId;
  const sceneLayerId = (nodeData as any).sceneItemId;
  const terrainLayerId = (nodeData as any).terrainItemId;

  const { allChecked, anyChecked, checkedCount, checkedHash } = useProjectPlanningTreeCheckStatus([...parentKeys, metadataKeyAndtitle]);
  const projectPlanningData = useSelector((state: StoreState) => state.projectPlanning);
  const dispatch = useDispatch();
  const { activeView } = useContext(ArcGISViewContext);


  const setLayerVisibilityAndDefinitionExpression = (layerId: string, useDefinitionExpression: boolean = true) => {
    const layerFromSearch = activeView.map.findLayerById(layerId) as __esri.FeatureLayer | __esri.SceneLayer;
    if (!layerFromSearch) return;

    layerFromSearch.visible = anyChecked;
    console.debug(`setLayerVisibilityAndDefinitionExpression: ${layerId}, visible: ${anyChecked}`);

    if (useDefinitionExpression && allChecked) {
      if (activeView.type === "2d") {
        activeView.whenLayerView(layerFromSearch as __esri.FeatureLayer).then((layerView) => {
          (layerView as any).filter = {
            where: "",
          };
        });
      }
      else if (activeView.type === "3d") {
        layerFromSearch.definitionExpression = "";
      }
      return;
    }

    if (useDefinitionExpression && anyChecked) {
      const selectedNames = getAllLeafItemsWithSameItemId(projectPlanningData, layerId);

      if (anyChecked && selectedNames.length > 0) {
        const defExp = `DocName IN ('${selectedNames.join("','")}')`;
        console.debug(`setLayerVisibilityAndDefinitionExpression: ${layerId}, defExp: ${defExp}`);

        if (activeView.type === "2d") {
          activeView.whenLayerView(layerFromSearch as __esri.FeatureLayer).then((layerView) => {
            (layerView as any).filter = {
              where: defExp,
            };
          });
        }
        else if (activeView.type === "3d") {
          layerFromSearch.definitionExpression = defExp;
        }
      }
    }
  };

  const setLayerVisibility = () => {
    if (activeView.type === "2d" && mapLayerId) {
      setLayerVisibilityAndDefinitionExpression(mapLayerId);
    } else if (activeView.type === "3d" && (sceneLayerId || terrainLayerId)) {
      setLayerVisibilityAndDefinitionExpression(sceneLayerId);
      if (terrainLayerId) setLayerVisibilityAndDefinitionExpression(terrainLayerId, false);
    }
  }

  useEffect(() => {
    console.debug(`setLayerVisibility::anyChecked:${anyChecked} allChecked:${allChecked} checkedCount:${checkedCount}`);
    setLayerVisibility();
  }, [checkedHash, anyChecked, allChecked]);

  const onZoomToLayer = (extentString: string) => {
    zoomToExtent(activeView, extentString);
  };

  const CollapsibleItemMenuDiv = styled("div")(({ theme }) => ({
    padding: "5px 20px 5px 10px",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    userSelect: "none",
    marginLeft: `${(parentKeys?.length ?? 0) * 15}px`,
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  }));

  return (
    <>
      <CollapsibleItemMenuDiv
        style={{
          padding: "5px 20px 5px 10px",
          display: "flex",
          cursor: "pointer",
          alignItems: "center",
          userSelect: "none",
          marginLeft: `${(parentKeys?.length ?? 0) * 15}px`,
        }}
      >
        {hasCheckbox && <Checkbox
          name={metadataKeyAndtitle + Math.floor(Math.random() * 10000)}
          checked={allChecked}
          indeterminate={anyChecked && !allChecked}
          onChange={() => {
            dispatch(
              selectAllLeafItems({
                parentKeys,
                leafKey: metadataKeyAndtitle,
                newValue: !allChecked,
              })
            )
          }
          }
        />
        }

        <Card
          sx={{
            backgroundColor: "transparent",
          }}
          elevation={0}
          onClick={() => {
            dispatch(expandCollapseMenu({ parentKeys, title: metadataKeyAndtitle }));
          }}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flex: 1,
          }}
        >
          <Typography
            sx={{
              color: "text.primary",
              fontWeight: "bold",
              fontSize: parentKeys?.length === 0 ? "20px" : "16px",
            }}
          >
            {metadataKeyAndtitle}
          </Typography>
          {nodeData.expanded ? <ExpandLess /> : <ExpandMore />}
        </Card>
      </CollapsibleItemMenuDiv>
      <Collapse in={nodeData.expanded}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <NestedLayerItem
            projectPlanningMetadata={nodeData.node as ProjectPlanningNodeType}
            parentKeys={[...(parentKeys ?? []), metadataKeyAndtitle]}
            onZoomToLayer={onZoomToLayer}
          />
        </div>
      </Collapse>
    </>
  );
};

export default LayerItemCollapsiblePartition;
