import { Theme, createTheme } from "@mui/material/styles";

const theme = (darkmode: boolean) =>
  createTheme({
    palette: {
      mode: darkmode ? "dark" : "light",
      ...(darkmode
        ? {
            background: {
              default: "#16273c",
            },
            text: {
              primary: "#f1f1f1",
              secondary: "#afafaf",
              disabled: "#808080",
            },
            primary: {
              main: "#7bf8d9",
              light: "#7bf8d9",
            },
            secondary: {
              main: "#0B1528",
              light: "#455163",
              dark: "#2E3B4F",
            },
            divider: "#455163",
            info: {
              main: "#f1f1f1",
            },
          }
        : {
            background: {
              default: "#ffffff",
            },
            text: {
              primary: "#003d6a",
              secondary: "#7c7c7c",
              disabled: "#808080",
            },
            primary: {
              main: "#337abe",
              light: "#7bf8d9",
            },
            secondary: {
              main: "#003D6A",
              light: "#EAF1FF",
              dark: "#F7F7F7",
            },
            divider: "#d3d3d3",
            info: {
              main: "#7c7c7c",
            },
          }),
    },

    components: {
      MuiSwitch: {
        styleOverrides: {
          root: {
            width: 30,
            height: 18,
            padding: 0,

            "&:active": {
              "& .MuiSwitch-thumb": {
                width: 15,
              },
              "& .MuiSwitch-switchBase.Mui-checked": {
                transform: "translateX(9px)",
              },
            },
            "& .MuiSwitch-switchBase": {
              padding: 2,
              "&.Mui-checked": {
                transform: "translateX(12px)",
                "& + .MuiSwitch-track": {
                  opacity: 1,
                  backgroundColor: "primary.main",
                },
              },
            },
            "& .MuiSwitch-thumb": {
              // boxShadow: "0 2px 4px 0 rgb(0, 35, 11 / 20%)",
              width: 14,
              height: 14,
              borderRadius: 6,
              transition: "width 200ms",
              backgroundColor: "#fff",
            },
            "& .MuiSwitch-track": ({ theme }: { theme: Theme }) =>
              theme.unstable_sx({
                borderRadius: 16 / 2,
                opacity: 1,
                backgroundColor: "secondary.light",
                boxSizing: "border-box",
              }),
          },
        },
      },

      MuiTextField: {
        styleOverrides: {
          root: {
            width: "100%",
            "& .MuiInputBase-root": ({ theme }: { theme: Theme }) =>
              theme.unstable_sx({
                backgroundColor: darkmode
                  ? "rgba(0, 0, 0, 0.3)"
                  : "rgba(255, 255, 255, 0.5)",
                borderRadius: 4,
                padding: "5px 10px",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: "secondary.light",
              }),
            "& .MuiInputBase-input": ({ theme }: { theme: Theme }) =>
              theme.unstable_sx({
                fontSize: 14,
                backgroundColor: "background.default",
              }),
          },
        },
      },

      MuiMenu: {
        styleOverrides: {
          paper: ({ theme }: { theme: Theme }) =>
            theme.unstable_sx({
              backgroundColor: "background.default",
              // color: "text.primary",
            }),
        },
      },

      MuiDialog: {
        styleOverrides: {
          paper: ({ theme }: { theme: Theme }) =>
            theme.unstable_sx({
              backgroundColor: "background.default",
              color: "background.default",
            }),
        },
      },

      MuiChip: {
        styleOverrides: {
          deletable: ({ theme }: { theme: Theme }) =>
            theme.unstable_sx({
              backgroundColor: "primary.main",
              color: "secondary.main",
            }),
          deleteIcon: ({ theme }: { theme: Theme }) =>
            theme.unstable_sx({
              color: "background.default",
            }),
        },
      },
    },

    typography: {
      fontFamily: "Barlow, sans-serif",
      h1: {
        color: "var(--color-blue)",
        fontSize: 30,
      },
      h2: {
        fontSize: 15,
        fontWeight: 400,
      },
      h3: {
        fontFamily: "Barlow Semi Condensed, sans-serif",
        fontSize: 21,
        fontWeight: 600,
      },
    },
  });

export default theme;
