import { Typography } from "@mui/material"
import { useSelector } from "react-redux"
import { StoreState } from "../../../../store/rootReducer"
import theme from "../../../../styles/theme"

const ProfileContactItem = (props: {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>
  value?: string
}) => {
  const { Icon, value } = props

  const darkmode = useSelector((state: StoreState) => state.app.darkmode)

  return (
    <div className="profile-contact">
      <Icon fill={theme(darkmode).palette.info.main} />
      <Typography sx={{ color: "text.primary" }}>{value ?? ""}</Typography>
    </div>
  )
}

export default ProfileContactItem
