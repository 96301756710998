import { createSlice } from "@reduxjs/toolkit"

interface MenuState {
  activeMenuItem: number
  expanded: boolean
  visibleMobile: boolean
}

const SidebarItems = {
  NONE: -1,
  RESETMAP: 0,
  BASEMAPS: 1,
  PROJECTPLANNING: 2,
  HSEQ: 3,
  MEDIA: 4,
  MACHINES: 5,
  DRAWMAP: 6,
  FAVORITES: 7,
  BOOKMARKS: 8,
  PROFILE: 9,
  SETTINGS: 10,
  MENU: 11,
}

const initialMenuState: MenuState = {
  activeMenuItem: SidebarItems.NONE,
  expanded: false,
  visibleMobile: false,
}

const menuSlice = createSlice({
  name: "menu",
  initialState: initialMenuState,
  reducers: {
    setActiveMenuItem(state, action) {
      if (state.activeMenuItem === action.payload) {
        state.activeMenuItem = SidebarItems.NONE
      } else {
        state.activeMenuItem = action.payload
      }
    },
    closeMenuItems(state) {
      state.activeMenuItem = SidebarItems.NONE
    },
    toggleExpanded(state) {
      state.expanded = !state.expanded
    },
    setExpanded(state, action) {
      state.expanded = action.payload
    },
    toggleVisibleMobile(state) {
      state.visibleMobile = !state.visibleMobile
    },
    setVisibleMobile(state, action) {
      state.visibleMobile = action.payload
    },
  },
})

export default menuSlice.reducer
export const {
  setActiveMenuItem,
  closeMenuItems,
  toggleExpanded,
  setExpanded,
  toggleVisibleMobile,
  setVisibleMobile,
} = menuSlice.actions
export { SidebarItems }
export type { MenuState }
